import React from 'react';
import styles from './style.module.sass';
import { IMapFrameProps } from "./mapframe.interface";
import clsx from "clsx";

export function MapFrame({ size, rotate, children, durationRotate = false }: IMapFrameProps) {

  return (
    <div
      style={{
        width: size,
        height: size,
        transform: `rotate(${rotate || 0}deg)`,
        overflow: 'hidden',
      }}
      className={clsx(
        styles.mapframe,
        { [styles.durationrotate]: durationRotate },
      )}
    >
      {children}
    </div>
  );
}
