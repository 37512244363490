import React from 'react';
import { Button } from "../Button";
import { IScaleButtonsProps } from "./interfaces";
import { HandySvg } from 'handy-svg';

import styles from './style.module.sass';
import arrow from './arrow.svg';
import menu4 from './menu4.svg';
import menu9 from './menu9.svg';
import clsx from "clsx";

export function ScaleButtons({ value, setValue, range = [600, 2000], step = 200, className = '', type = 'arrow' }: IScaleButtonsProps) {
  return (
    <div
      className={`${styles.scalebtns} ${className}`}
      onWheel={e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.deltaY < 0 && ((value + step) <= range[1])) {
          setValue(value + step);
        }
        if (e.deltaY > 0 && ((value - step) >= range[0])) {
          setValue(value - step);
        }
      }}
    >
      <Button
        onClick={() => (value + step) <= range[1] && setValue(value + step)}
        className={clsx(styles.btn, styles.btnplus)}
      >
        {type === 'plus' && <div className={styles.plus}>+</div>}
      </Button>
      <Button
        onClick={() => (value - step) >= range[0] && setValue(value - step)}
        className={clsx(styles.btn, styles.btnminus)}
      >
        {type === 'plus' && <div className={styles.minus}>-</div>}
        {type === 'multi' && <HandySvg
          className={styles.minus}
          src={menu4}
          width={20}
          height={20}
        />}
      </Button>
      {(type === 'arrow' || type === 'multi') && <div
        className={clsx(styles.btn, styles.arrow)}
        onClick={() => (value + step) <= range[1] && setValue(value + step)}
      >
        {type === 'arrow' && <HandySvg src={arrow} width={40} height={40} />}
        {type === 'multi' && <HandySvg className={styles.dot} src={menu9} width={30} height={30} />}
      </div>}
    </div>
  );
}
