import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { googleLogout } from '@react-oauth/google';
import { userContext } from '../../providers/UserProvider';

export default function Logout() {
  const navigate = useNavigate();
  const { getToken, removeToken, removeGameId } = useContext(userContext);
  const token = getToken();

  useEffect(() => {
    removeToken();
    removeGameId();
    googleLogout();

    navigate('/');
  }, [token]);

  return (<></>)
}
