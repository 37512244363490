import React from 'react';
import { IScoreFieldLetterProps } from './score.interface';
import styles from './style.module.sass';

export function ScoreFieldLetter({ len, size, count, children, isConcave }: IScoreFieldLetterProps) {
  const unitSize = size / 26;
  const unitTranslate = size / 3;

  const maxLength = 14;
  const symbolSize = 180 / maxLength;

  const symbolShift = symbolSize * count + ((maxLength - len) / 2) * symbolSize + (symbolSize / 2);

  return (
    <div
      className={styles.letter}
      style={{
        position: 'absolute',
        transform: `rotate(${isConcave ? '' : '-'}${symbolShift}deg) translate(${unitTranslate}px)`,
        width: `${unitSize}px`,
        height: `${unitSize}px`,
        margin: `-${unitSize / 2}px`,
        fontSize: `${size / maxLength}px`,
      }}
    >
      <div style={{ transform: `rotate(${isConcave ? '-' : ''}90deg)` }}>
        {children}
      </div>
    </div>
  );
}