export const generateSquareMap = (side: number) => {
  const map: { [rkey: number]: { [key: number]: number; } } = {};

  for (let i = 0; i <= side; i++) {
    const col: { [key: number]: number; } = {};
    for (let j = 0; j <= side; j++) {
      const isBorder = (i === 0 || j === 0 || i === side || j === side);
      const isCorner = ((i === 1 && j === 1) || (i === 1 && j === (side - 1)) || (i === (side - 1) && j === 1) || (j === (side - 1) && i === (side - 1)));
      col[j] = isBorder || isCorner ? -1 : 0;
    }
    map[i] = col;
  }

  return map;
}