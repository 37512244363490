import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { requestContext } from "../../providers/RequestProvider";
import { Form } from "../../ui/Form";
import { Button } from "../../ui/Buttons/Button";
import { Icon } from "../../ui/Icon";
import { IAuthFormFields } from './auth-form-fields.interface';
import { IRegFormFields } from "../registration/interfaces/reg-form-fields.interface";
import { EICON } from "../../ui/Icon/eicon.enum";
import styles from './style.module.sass';

const fields = {
  email: {
    id: 'email',
    label: 'Email',
    name: 'email',
    regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    error: 'Введен некорректный адрес Email',
    type: 'email',
    value: '',
  },
  password: {
    id: 'password',
    label: 'Пароль',
    name: 'password',
    regex: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,50}$/,
    error: 'Пароль должен содержать хотя бы одну заглавную и одну строчную букву, цифру, и спецсимвол. ' +
      'Длина пароля от 10 до 50 символов',
    type: 'password',
    value: '',
  },
}

export default function Authentification() {
  const navigate = useNavigate();
  const [values, setValues] = useState<IAuthFormFields>({});
  const { request } = useContext(requestContext);

  useEffect(() => {
    if (values.email && values.password) {
      request<IRegFormFields, any>({
        data: values,
        method: 'POST',
        path: 'auth/login',
        success: (data) => {
          if (data && data.userId && data.username && data.accessToken && data.expiresIn) {
            navigate('/login', {
              state: data,
            });
          }
        },
      });
    }
  }, [values]);

  return (
    <div className={styles.authentificationform}>
      <Form
        className={styles.form}
        submitText={<Icon name={EICON.login} size={50} />}
        giveData={(value) => setValues(value as IAuthFormFields)}
        fields={fields}
        altCmp={
          <Link to={"/registration"}>
            <Button>
              <Icon name={EICON.signin} size={50} />
            </Button>
          </Link>
        }
      />
    </div>
  )
}
