import React, { useContext } from 'react';
import { notifyContext } from '../../providers/NotifyProvider';
import Notify from './Notify/Notify';
import styles from './style.module.sass';

export function NotifyBox() {
  const { notify } = useContext(notifyContext);

  return (
    <div className={styles.notifybox}>
      {notify.map(({ notifyId, msg, type, title }) => (
        <Notify key={notifyId} type={type} header={title} id={notifyId}>
          {msg}
        </Notify>
      ))}
    </div>
  );
}
