import React, { useContext } from 'react';
import styles from './style.module.sass';
import { IUnitFieldProps } from './interfaces';
import { Unit } from '../Unit';
import { UnitFieldItem } from './UnitFieldItem';
import { LabyrinthItem } from './LabyrinthItem';
import { MoneyItem } from './MoneyItem';
import { gameContext } from '../../../providers/GameProvider';
import { sameCoords } from '../../../lib/sameCoords';
import { Ship } from '../Ship';
import { repeat } from '../../../lib/repeat';

export function UnitField({ coords: [x, y], size: [width, height] }: IUnitFieldProps) {
  const { map, units } = useContext(gameContext);
  const field = map[x][y];
  const curUnits = units && Object.values(units).filter(({ coords }) => sameCoords(coords, [x, y]));
  const isShip = curUnits.find(unit => unit.type === 'ship');

  const isLabyrinth = field.type === 'labyrinth';
  const isGold = field.gold && field.gold > 0;
  const len = isLabyrinth ? (field.count || 0) : curUnits.length - ((curUnits.length > 2 && isShip) ? 1 : 0);
  const unitSize = width * 0.25;
  const unitTranslate = unitSize;
  const angle = 360 / len;

  return (
    <div className={styles.circle} style={{ width, height }}>
      {isGold ? (
        <UnitFieldItem
          key={len - 1}
          inCenter={true}
          count={len - 1}
          angle={angle}
          size={width * 0.2}
          translate={unitTranslate}
          type={'gold'}
        >
          <MoneyItem
            money={field.gold || 0}
            size={unitSize}
            coords={[x, y]}
          />
        </UnitFieldItem>
      ) : ''}
      {(isLabyrinth && field.count) ? repeat(field.count, (i) => (
          <UnitFieldItem
            key={`labyrinthItem_${i + 1}`}
            count={i}
            angle={angle}
            size={width * 0.2}
            translate={unitTranslate}
            type={'labyrinth'}
          >
            <LabyrinthItem count={i + 1} size={unitSize}>
              {curUnits.filter(unit => unit.labyrinth === (i + 1)).map(unit => (
                <Unit
                  id={unit.id}
                  key={`labyrinthItem_${i + 1}_unit_${unit.id}`}
                  size={unitSize}
                  userId={unit.userId}
                  style={{ position: 'absolute' }}
                  gold={unit.gold}
                  isDead={unit.isDead}
                />
              ))}
            </LabyrinthItem>
          </UnitFieldItem>
        ))
        : units.length ? (
            curUnits
              .sort((a, b) => Number(a.type === 'unit') - Number(b.type === 'unit'))
              .map(({ userId, id, gold, type, isDead }, i) => (
                type === 'ship'
                  ? <Ship coords={[x, y]} size={width} key={id} />
                  : <UnitFieldItem
                      inCenter={curUnits.length === 1 && !isGold}
                      key={i}
                      count={i}
                      angle={angle}
                      size={unitSize}
                      translate={unitTranslate}
                      isEven={curUnits.length % 2 !== 0}
                      type={`unit_${x}_${y}`}
                  >
                    <Unit
                      id={id}
                      size={unitSize}
                      userId={userId}
                      gold={gold}
                      isDead={isDead}
                    />
                  </UnitFieldItem>
            ))
          ) : ''
        }
    </div>
  );
}
