export enum EColor {
  black = 'black',
  orange = 'orange',
  green = 'green',
  white = 'white',
  purple = 'purple',
  greyF4 = 'greyF4',
  greyF3 = 'greyF3',
  greyD9 = 'greyD9',
  greyC4 = 'greyC4',
  grey99 = 'grey99',
  grey66 = 'grey66',
}