import { useContext, useEffect } from 'react';
import { usePageMountType } from "./interfaces/usePageMount.interface";
import { pagesContext } from "../providers/PagesProvider";

export const usePageMount: usePageMountType = (fn, page) => {
  const { activePanel } = useContext(pagesContext);

  useEffect(() => {
    if (page === activePanel) {
      fn();
    }
  }, [activePanel]);
}