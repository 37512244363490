import React, { useContext, useEffect } from 'react';
import fetchJsonp from 'fetch-jsonp';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Page from '../page/Page';
import Start from '../start/Start';
import Create from '../create/Create';
import Choose from '../choose/Choose';
import ChangeMap from '../mapeditor/ChangeMap/ChangeMap';
import Play from '../play/Play';
import { emptyMap } from '../mapeditor/MapEditor';
import { requestContext } from '../../providers/RequestProvider';
import { userContext } from '../../providers/UserProvider';
import { IVkAuthFormFields } from '../vk/interfaces';
import { IUser } from '../../interfaces/user.interface';

export default function Main() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { request } = useContext(requestContext);
  const { setUserId, setToken } = useContext(userContext);

  const createUser = async (data: {
    userId: number,
    accessToken: string,
    expiresIn: number,
    email: string,
    username: string,
  }) => {
    request<IVkAuthFormFields, any>({
      data,
      method: 'POST',
      path: 'auth/vk',
      success: (data: IUser) => {
        if (data && data.username && data.userId && data.accessToken && data.expiresIn) {
          setUserId(data.userId);
          setToken(data.accessToken, +data.expiresIn);
          navigate('/login', {
            state: data,
          });
        }
      },
    });
  };

  useEffect(() => {
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      if (params.has('user_id') && params.has('access_token')) {
        fetchJsonp(
          `https://api.vk.com/method/users.get`
          + `?user_ids=${params.get('user_id')}`
          + `&access_token=${params.get('access_token')}`
          + `&v=5.199`
        )
          .then(response => response.json())
          .then(function(json) {
            const user = json.response[0];
            createUser({
              userId: Number(params.get('user_id')),
              accessToken: params.get('access_token') || '',
              expiresIn: params.has('expires_in') ? Number(params.get('expires_in')) : 86400,
              email: params.get('email') || '',
              username: `${user.first_name} ${user.last_name}`,
            });
          })
          .catch(response => {
            console.log('parsing failed', response)
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <>
      <Page key='start' id='start'>
        <Start />
      </Page>
      <Page key='create' id='create'>
        <Create />
      </Page>
      <Page key='choose' id='choose'>
        <Choose />
      </Page>
      <Page key='play' id='play'>
        <Play />
      </Page>
      <Page key='edit' id='edit'>
        <ChangeMap
          map={{
            ...emptyMap,
            mapId: 'empty',
            position: [0, 0],
          }}
          setMap={() => {}}
          editMode={true}
        />
      </Page>
    </>
  );
}