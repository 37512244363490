import React, { useContext, useEffect, useState } from 'react';
import { usePageMount } from '../../hooks/usePageMount';
import { userContext } from '../../providers/UserProvider';
import { gameContext } from '../../providers/GameProvider';
import { pagesContext } from '../../providers/PagesProvider';
import { socketContext } from '../../providers/SocketProvider';
import { modalContext } from "../../providers/ModalProvider";
import { PAGES } from '../../providers/PagesProvider/pagesConfig';
import { ExitButton } from '../../ui/Buttons/ExitButton';
import GameOver from "../../ui/GameOver/GameOver";
import { Compass } from '../../ui/Compass';
import { Scale } from '../../ui/Scale';
import { Map } from '../../ui/Map';
import { EICON } from '../../ui/Icon/eicon.enum';
import { IGame } from '../../interfaces/game.interface';

export default function Play() {
  const { setActivePanel } = useContext(pagesContext);
  const { showModal } = useContext(modalContext);
  const { getUserId, getGameId, removeGameId } = useContext(userContext);
  const { setGame, removeGame, map, units, setUserShip, setRotate, rotate } = useContext(gameContext);
  const { socketConnect, socketDisconnect, socketOn, socketOff, socketEmit, socketId } = useContext(socketContext);
  const [size, setSize] = useState(800);
  const userId = getUserId();
  const gameId = getGameId();

  usePageMount(() => {
    socketConnect();
    setTimeout(
      () => setSize(Math.round(Math.min(window.innerWidth, window.innerHeight, 1400) / 100) * 100),
      100,
    );
  }, PAGES.play.path);

  useEffect(() => {
    if (socketId) {
      socketOn('game', (game: IGame) => {
        console.log('game', game);
        setGame(game);
      });

      socketOn('gameover', ({ isWinner, gold = 0, type, rating }) => {
        console.log('gameover', { isWinner, gold, type, rating });
        showModal(
          <GameOver
            isWinner={isWinner}
            gold={gold}
            type={type}
            rating={rating}
          />
        );
      });

      socketEmit('join', { userId, gameId });

      return () => {
        socketOff('game');
        socketOff('gameover');
      };
    }
  },[socketId]);

  useEffect(() => {
    if (units.length) {
      const ship = units.find(unit => unit.userId === userId && unit.type === 'ship');
      if (ship) {
        setUserShip(ship);
        setRotate(ship.rotate);
      }
    }
  }, [units]);

  return (
    <>
      <Compass
        size={Math.round((window.innerWidth + window.innerHeight) / 15)}
        rotate={rotate || 0}
      />
      {map ? (
        <Scale size={size} giveSize={setSize}>
          <Map size={size}/>
        </Scale>
      ) : 'Loading...'}
      <ExitButton
        icon={EICON.shipwreck}
        onClick={() => {
          socketEmit('exit', { userId });
          removeGame();
          removeGameId();
          socketDisconnect();
          setActivePanel(PAGES.start.path);
        }}
      />
    </>
  );
}
