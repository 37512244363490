import React from 'react';
import clsx from 'clsx';
import { HexButton } from '../Buttons/HexButton';
import { SquareButton } from '../Buttons/SquareButton';
import { Circle } from '../Circle';
import { ICreateGameFieldProps } from './creategamefield.interface';
import styles from './style.module.sass';
import icons from './ship.svg';
import { HandySvg } from 'handy-svg';

export function CreateGameField({ fields, places, shape, occupied = 0, isActive = false, size }: ICreateGameFieldProps) {
  let countPlaces = 0;

  if (places && typeof places === 'object') {
    countPlaces = Object.keys(places).length;
  }

  const islandSize = size * fields / 650;
  const unitSize = size / 10;

  return (
    <div
      data-id={"field"}
      className={clsx(
        styles.field,
        { [styles.inactive]: countPlaces === occupied || isActive },
      )}
    >
      <div className={styles.countplayer}>
        <span className={styles.header}>
          {shape === 4
            ? <SquareButton className={styles.earth} size={islandSize} />
            : <HexButton className={styles.earth} size={islandSize} />
          }
        </span>
        <div className={styles.circle}>
          <Circle
            count={+countPlaces || 0}
            size={(islandSize * 2) + (unitSize * 2)}
            uSize={unitSize}
            occupied={occupied}
          >
            <div>
              <HandySvg
                src={icons}
                width={unitSize * 0.9}
                height={unitSize * 0.9}
                className={styles.ship}
              />
            </div>
          </Circle>
        </div>
      </div>
    </div>
  );
}