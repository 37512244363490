import React, { useContext, useEffect, useState } from 'react';
import clsx from "clsx";
import { sameCoords } from "../../lib/sameCoords";
import { fieldMapGenerator } from "../../lib/fieldMapGenerator";
import { calculateHexOuter } from "../../lib/calculateHexOuter";
import { getBackgroundCommonStyle } from "../../lib/getBackgroundCommonStyle";
import { socketContext } from "../../providers/SocketProvider";
import { userContext } from "../../providers/UserProvider";
import { gameContext } from "../../providers/GameProvider";
import { HandySvg } from "handy-svg";
import { FieldButton } from '../Buttons/FieldButton';
import { MapFrame } from "./MapFrame";
import { UnitField } from './UnitField';
import Surface from "../Surface/Surface";
import { Ship } from "./Ship";
import { Unit } from "./Unit";

import { IAvatar } from "./interfaces";

import styles from './style.module.sass';

import crocodile from "./img/crocodile.svg";
import airplane from "./img/airplane.svg";
import cannibal from "./img/cannibal.svg";
import balloon from "./img/balloon.svg";
import horses from "./img/horses.svg";
import jungle from "./img/jungle.svg";
import arrow from "./img/arrow.svg";
import woman from "./img/woman.svg";
import fort from "./img/fort.svg";
import gold from "./img/gold.svg";
import trap from "./img/trap.svg";
import cave from "./img/cave.svg";
import ice from "./img/ice.svg";
import rum from "./img/rum.svg";
import gun from "./img/gun.svg";


const eicon = {
  crocodile: crocodile,
  airplane: airplane,
  cannibal: cannibal,
  balloon: balloon,
  horses: horses,
  jungle: jungle,
  woman: woman,
  arrow: arrow,
  cave: cave,
  trap: trap,
  fort: fort,
  gold: gold,
  ice: ice,
  rum: rum,
  gun: gun,
};

const edir4 = [315, 0, 45, 270, 90, 225, 180, 135];
const edir6 = [330, 30, 270, 90, 210, 150];

export function Map({ size }: { size: number }) {
  const { socketOn, socketOff, socketId, socketEmit } = useContext(socketContext);
  const { getUserId } = useContext(userContext);
  const { map, rotate, shape, steps, setSteps, units } = useContext(gameContext);

  const scrollEl = document.querySelector<HTMLDivElement>(`#scrollbar`);
  const { x: sx, y: sy } = scrollEl ? scrollEl.getBoundingClientRect(): { x: 0, y: 0 };

  const [avatars, setAvatars] = useState<IAvatar[] | null>(null);
  const [duration, setDuration] = useState<number>(0);

  const userId = getUserId();
  const fieldSize = size/Object.values(map).length;

  const handleClick = (newCoords: number[]) => {
    if (steps.find((coords) => sameCoords(coords, newCoords))) {
      socketEmit('step', newCoords);
    }
  }

  useEffect(() => {
    if (socketId) {
      socketOn('steps', ({ steps }) => setSteps(steps));

      return () => socketOff('steps');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketId]);

  const changeAvatar = (calcDuration?: boolean) => {
    if (size && units) {
      let duration = 0;
      const avatars = units.map(unit => {
        const el = document.querySelector<HTMLDivElement>(`#${unit.id}`);
        const { x, y, width, height } = el ? el.getBoundingClientRect(): { x: 0, y: 0, width: 0, height: 0 };

        if (calcDuration && unit.isActive) {
          duration = 500;
        }

        return {
          ...unit,
          x: x - sx,
          y: y - sy,
          width,
          height,
        };
      });

      setDuration(duration);
      setTimeout(() => setAvatars(avatars), 0);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeAvatar(true), [units]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeAvatar(false), [size]);

  return (
    <div data-id={'mapa'}>
      <MapFrame size={size} rotate={rotate} shape={shape} key={'maoframe'}>
        {fieldMapGenerator(
          { map, size, shape },
          (value, coords, len, childProps) =>
            <FieldButton
              onClick={() => handleClick(coords)}
              field={value.code}
              name={value.name}
              gold={value.gold || 0}
              description={value.description}
              children={<>
               {value.code !== 'sea' && (<>
                 {typeof value.code === 'string' && value.code !== 'earth' &&
                   <Surface
                     surface={value.surface}
                     size={childProps.size * 0.9}
                     rotate={rotate}
                   />
                 }
                  {value.type in eicon && (
                    ['arrow', 'gun'].includes(value.type) && value.directions
                      ? value.directions.map((dir, i, arr) =>
                        <div
                          key={value.type + i}
                          className={clsx(
                            styles.iconcontainer,
                            { [styles[`arrow${arr.length}`]]: value.type === 'arrow' },
                          )}
                          style={{ transform: `rotate(${shape === 4 ? edir4[dir] : edir6[dir]}deg)` }}
                        >
                          <HandySvg
                            //@ts-ignore
                            src={eicon[value.type]}
                            width={childProps.size * 0.7}
                            height={childProps.size * 0.7}
                          />
                        </div>
                      )
                      : (
                        <div
                          className={styles.iconcontainer}
                          style={{
                            transform: `rotate(${-rotate}deg)`,
                          }}
                        >
                          <HandySvg
                            //@ts-ignore
                            src={eicon[value.type]}
                            width={childProps.size * 0.7}
                            height={childProps.size * 0.7}
                          />
                        </div>
                      )
                  )}
               </>)}
                <UnitField
                  size={[fieldSize, shape === 4 ? fieldSize : calculateHexOuter(fieldSize)]}
                  coords={coords}
                />
              </>}
              className={clsx(
                styles[value.type === 'sea' ? 'sea' : 'earth'],
                { [styles.nohoverbutton]: !steps.length || !steps.find(([x, y]) => sameCoords(coords, [x, y])) },
              )}
              isAllowed={!!(steps && steps.find(([x, y]) => sameCoords(coords, [x, y])))}
              style={getBackgroundCommonStyle(coords, size, len, shape, 0)}
              {...childProps}
            />
        )}
      </MapFrame>
      {userId && avatars && avatars
        .sort((a, b) => Number(a.type === 'unit') - Number(b.type === 'unit'))
        .map((unit, i) =>
        <div
          key={i}
          onClick={() => handleClick(unit.coords)}
          style={{
            position: 'absolute',
            top: unit.y,
            left: unit.x,
            width: unit.width,
            height: unit.height,
            transition: `top ${duration}ms linear 0s, left ${duration}ms linear 0s`,
          }}
          className={clsx({ [styles.shiprolling]: unit.type === 'ship' })}
        >
          {unit.type === 'unit' ? (
            <Unit
              id={unit.id}
              size={fieldSize * 0.25}
              userId={userId}
              gold={unit.gold}
              isDead={unit.isDead}
              avatar={true}
            />
          ) : (
            <Ship
              size={fieldSize}
              coords={unit.coords}
              avatar={true}
            />
          )}
        </div>
      )}
    </div>
  );
}
