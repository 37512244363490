import React, { createContext, useEffect, useState } from 'react';
import { IProvidersProps } from '../interfaces/providers.interface';
import { IGame } from "../../interfaces/game.interface";
import { IGameContextProps } from "../interfaces/game-provider.interface";
import { IOpenedMap } from "../../interfaces/opened-map.interface";
import { IUnit } from "../../interfaces/unit.interface";

export const gameContext = createContext<IGameContextProps>({
  setGame: () => {},
  removeGame: () => {},
  map: {},
  shape: 0,
  side: 5,
  units: [],
  rotate: 0,
  setRotate: () => {},
  activeShip: null,
  setActiveShip: () => {},
  userShip: null,
  setUserShip: () => {},
  isUserShipActive: false,
  setIsUserShipActive: () => {},
  userUnits: [],
  setUserUnits: () => {},
  steps: [],
  setSteps: () => {},
  unitHover: 0,
  setUnitHover: () => {},
});

export function GameProvider({ children }: IProvidersProps) {
  const { Provider } = gameContext;
  const [map, setMap] = useState<IOpenedMap>({});
  const [side, setSide] = useState<number>(5);
  const [shape, setShape] = useState<number>(0);
  const [rotate, setRotate] = useState<number>(0);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [activeShip, setActiveShip] = useState<IUnit | null>(null);
  const [userShip, setUserShip] = useState<IUnit | null>(null);
  const [userUnits, setUserUnits] = useState<IUnit[] | null>(null);
  const [isUserShipActive, setIsUserShipActive] = useState<boolean>(false);
  const [steps, setSteps] = useState<number[][]>([]);
  const [unitHover, setUnitHover] = useState<number>(0);

  const setGame = (game: IGame): void => {
    setMap(game.map);
    setSide(game.side);
    setShape(game.shape);
    setUnits(game.units);

    const activeShip = game.units.find(({ type, isActive }) => isActive && type === 'ship');
    if (activeShip) {
      setActiveShip(activeShip);
    }
  }

  const removeGame = (): void => {
    setMap({});
    setSide(0);
    setShape(0);
    setUnits([]);
    setActiveShip(null);
  }

  useEffect(() => {
    setIsUserShipActive(activeShip?.userId === userShip?.userId);
    if (units.length && userShip?.userId) {
      setUserUnits(units.filter(({ userId }) => userId === userShip?.userId));
    }
  }, [activeShip, userShip]);

  return (
    <Provider value={{
      setGame, removeGame, map, units, side, shape, rotate, setRotate,
      activeShip, setActiveShip, steps, setSteps,
      userShip, setUserShip, isUserShipActive, setIsUserShipActive, userUnits, setUserUnits, unitHover, setUnitHover
    }}>
      {children}
    </Provider>
  );
}
