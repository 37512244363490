import React from 'react';
import { HexButton } from "../Buttons/HexButton";
import { Icon } from "../Icon";
import { EICON } from "../Icon/eicon.enum";
import { ICreateNewGameProps } from "./createnewgame.interface";
import styles from './style.module.sass';

export function CreateNewGame({ createBtnsClick, position, size }: ICreateNewGameProps) {
  return (
    <HexButton
      size={size}
      className={styles.creategamebtns}
      onClick={() => createBtnsClick({
        position: position,
      })}
    >
      <div className={styles.arrowicon}>
        <Icon name={EICON.plus} size={size / 2} />
      </div>
    </HexButton>
  );
}