export const generateHexMap = (side: number) => {
  const map: { [rkey: number]: { [key: number]: number; } } = {};
  for (let i = 0; i < ((side * 2) - 1); i++) {
    const col: { [key: number]: number; } = {};
    const rowLength = (side + i) >= ((side * 2)) ? ((side * 2) - i + (side - 2)) : (side + i);
    for (let j = 0; j < rowLength; j++ ) {
      const isBorder = (i === 0 || j === 0 || i === (side * 2 - 2) || j === rowLength - 1);
      col[j] = isBorder ? -1 : 0;
    }
    map[i] = col;
  }

  return map;
}
