import React from 'react';
import styles from './style.module.sass';
import icons from "./wind-rose.svg";
import { HandySvg } from "handy-svg";

export function Compass({ rotate, size }: { rotate: number, size: number }) {
  const offsetSize = size * 0.15;
  const directions = [
    ['N', [0, 0, undefined, 0]],
    ['NE', [offsetSize, offsetSize, undefined, undefined]],
    ['E', [0, 0, 0, undefined]],
    ['SE', [undefined, offsetSize, offsetSize, undefined]],
    ['S', [undefined, 0, 0, 0]],
    ['SW', [undefined, undefined, offsetSize, offsetSize]],
    ['W', [0, undefined, 0, 0]],
    ['NW', [offsetSize, undefined, undefined, offsetSize]],
  ];

  return (
    <div
      className={styles.compass}
      style={{
        transform: `rotate(${rotate}deg)`,
        width: `${size * 1.1}px`,
        height: `${size * 1.1}px`,
      }}
    >
      {directions.map(([dir, coords], i) => (
        <div
          style={{
            transform: `rotate(-${rotate}deg)`,
            fontSize: `${size * 0.1}px`,
            lineHeight: `${size * 0.2}px`,
            width: `${size * 0.2}px`,
            height: `${size * 0.2}px`,
            top: coords[0] && `${coords[0]}px`,
            right: coords[1] && `${coords[1]}px`,
            bottom: coords[2] && `${coords[2]}px`,
            left: coords[3] && `${coords[3]}px`,
          }}
          key={String(dir)}
          className={styles.dirname}
        >
          {dir}
        </div>
      ))}
        <HandySvg
          src={icons}
          width={size * 0.9}
          height={size * 0.9}
          className={styles.rose}
        />
    </div>
  );
}
