import React from 'react';
import icons from './icons.svg';

export function Icon({ name, size = 100, className }: { name: string, size?: number, className?: string }) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
    >
      <use xlinkHref={`${icons}#${name}`} />
    </svg>
  );
}
