import React, { useContext, useRef } from 'react';
import { modalContext } from "../../providers/ModalProvider";
import { IconButton } from "../../ui/Buttons/IconButton";
import { EICON } from "../../ui/Icon/eicon.enum";
import styles from './style.module.sass';

export default function Modal({ closeBtn }: { closeBtn?: boolean }) {
  const { show, content, hideModal } = useContext(modalContext);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={styles.curtain}
      style={{
        display: show ? 'block' : 'none',
      }}
    >
      <div ref={ref} className={styles.window}>
        {closeBtn && <IconButton
          className={styles.close}
          icon={EICON.close}
          onClick={() => hideModal()}
        />}
        {content}
      </div>
    </div>
  );
}
