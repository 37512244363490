import React, { useContext, useEffect, useState } from 'react';
import { IChangeMapProps } from "./changemap.interface";
import { useRequest } from "../../../hooks/useRequest";
import FieldMapEditor from "../FieldMapEditor/FieldMapEditor";
import { IPlayers } from "../../../interfaces/players.interface";
import { ScaleButtons } from "../../../ui/Buttons/ScaleButtons";
import { ExitButton } from "../../../ui/Buttons/ExitButton";
import { IMapEdit } from "../interfaces/imapedit.interface";
import { emptyMap } from "../MapEditor";
import DeleteButton from "../../../ui/Buttons/DeleteButton/DeleteButton";
import SaveButton from "../../../ui/Buttons/SaveButton/SaveButton";
import { HexButton } from "../../../ui/Buttons/HexButton";
import { SquareButton } from "../../../ui/Buttons/SquareButton";
import clsx from "clsx";
import { generateSquareMap } from "../../../lib/generateSquareMap";
import { generateHexMap } from "../../../lib/generateHexMap";
import { modalContext } from "../../../providers/ModalProvider";
import { Loader } from "../../../ui/Loader";
import ChooseField from "../ChooseField/ChooseField";
import styles from "./style.module.sass";
import shovel from "./img/shovel.svg";
import icon from "./img/icon.svg";
import { HandySvg } from "handy-svg";
import { IPlayer } from "../../../interfaces/player.interface";
import { sameCoords } from "../../../lib/sameCoords";
import { Icon } from "../../../ui/Icon";
import { EICON } from "../../../ui/Icon/eicon.enum";
import { useLocation } from "react-router";
import { PAGES } from "../../../providers/PagesProvider/pagesConfig";
import { pagesContext } from "../../../providers/PagesProvider";
import { userContext } from "../../../providers/UserProvider";
import { socketContext } from "../../../providers/SocketProvider";

const minMaxShape = new Map([[4, [8, 20]], [6, [6, 12]]]);

const basePlayer: IPlayer = {
  coords: [0, 0],
  isInGame: false,
  playerId: '',
  userId: '',
  position: 0,
  rotate: 0,
  name: 'Игрок',
};

export default function ChangeMap({ map, setMap, editMode }: IChangeMapProps) {
  const { pathname } = useLocation();
  const { request } = useRequest();
  const { showModal, hideModal } = useContext(modalContext);
  const { getUserId, getGameId } = useContext(userContext);
  const { socketEmit } = useContext(socketContext);
  const [newMap, setNewMap] = useState(emptyMap);
  const [isShovel, setIsShovel] = useState(false);
  const [coords, setCoords] = useState<number[] | null>(null);
  const [rotate, setRotate] = useState(0);
  const [shipPosition, setShipPosition] = useState(0);
  const { setActivePanel } = useContext(pagesContext);
  const userId = getUserId();
  const gameId = getGameId();

  const saveMap = () => {
    if (Object.keys(newMap.places).length < 2) {
      alert('Не расставлены корабли!');

      return;
    }

    newMap.filling = [];
    let gold = 0;
    for (let row in newMap.map) {
      for (let key in newMap.map[row]) {
        const code = newMap.map[row][key];
        if (typeof code === 'string' && code.indexOf('gold') >= 0) {
          gold++;
        }
        if (typeof code === 'string') {
          newMap.filling.push(String(code));
          newMap.map[row][key] = 0;
        }
      }
    }


    if (gold === 0) {
      alert('Нужно добавить золото!');

      return;
    }

    const isWrite = ['empty', 'new'].includes(newMap.mapId);

    if (pathname !== '/maps') {
      request<any, string>({
        path: 'game/addyourmap',
        method: 'POST',
        data: {
          gameId,
          userId,
          map: newMap,
        },
        success: (gameId) => {
          if (gameId) {
            socketEmit('games');
            setActivePanel(PAGES.play.path);
          }
        },
      });

      return;
    }

    request<any, any>({
      path: 'maps',
      method: isWrite ? 'POST' : 'PATCH',
      data: {
        mapId: isWrite ? undefined : newMap.mapId,
        shape: newMap.shape,
        places: JSON.stringify(newMap.places),
        side: newMap.side,
        map: Object.values(newMap.map),
        position: newMap.position,
        freq: newMap.freq,
        filling: newMap.filling,
      },
      success: () => setMap(emptyMap),
    });
  }

  useEffect(() => {
    if (editMode && map.mapId !== '') {
      if (Object.keys(map.map).length === 0) {
        map.map = generateSquareMap(map.side);

        return;
      }

      doFilling(map);

        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const doFilling = async (newMap: IMapEdit) => {
    showModal(<Loader/>);
    request<any, any>({
      path: `maps/filling/`,
      method: 'POST',
      data: {
        map: newMap.map,
        freq: newMap.freq || 8,
        filling: newMap.filling,
      },
      failure: () => hideModal(),
      success: (map) => {
        setNewMap({
          ...newMap,
          map,
        });
        hideModal();
      },
    });
  }

  const generateNewMap = (args: any) => {
    const editMap = { ...newMap };
    const isFreqChanged = args.freq && args.freq !== editMap.freq;
    const isShapeChanged = args.shape && args.shape !== editMap.shape;
    let isSideChanged = args.side && args.side !== editMap.side;
    const rangeShape = minMaxShape.get(args.shape) || [8, 20];

    if (isShapeChanged) {
      editMap.mapId = 'empty';
      editMap.shape = args.shape;
      isSideChanged = true;
      setRotate(0);
    }

    if (isSideChanged) {
      editMap.mapId = 'empty';
      editMap.side = args.side || (rangeShape[1] - rangeShape[0]) / 2 + rangeShape[0];
    }

    if (isSideChanged || isShapeChanged) {
      const shapeFn = editMap.shape === 4 ? generateSquareMap : generateHexMap;
      editMap.map = shapeFn(editMap.side);
      editMap.places = {};
    }

    if (isFreqChanged) {
      editMap.freq = args.freq;
      editMap.filling = [];
    }

    if (isSideChanged || isShapeChanged || isFreqChanged) {
      doFilling(editMap);

      return;
    }
  }

  const changeFields = ([x, y]: number[]) => {
    const map = { ...newMap.map };
    const field = map[x][y];
    const isSea = field && typeof field === 'number';
    let shipEdit = false;
    let placeList: IPlayers = {};

    setShipPosition(0);

    let places = Object.values(newMap.places);

    if (places.find(({ coords }) => sameCoords([x, y], coords))) {
      places = places.map(place => ({
        ...place,
        isActive: sameCoords([x, y], place.coords) && !place.isActive,
      }));

      shipEdit = true;
    }

    const activePlace: IPlayer | undefined = places.find(({ isActive }) => isActive);
    if (activePlace) {
      setRotate(activePlace.rotate);
      places = places.map(place => ({
        ...place,
        coords: place.isActive && isSea ? [x, y] : place.coords,
      }));

      const newActivePlace: IPlayer | undefined = places.find(({ isActive }) => isActive);
      if (newActivePlace) {
        setRotate(newActivePlace.rotate);
        setShipPosition(newActivePlace.position);
      }

      shipEdit = true;
    }

    if (shipEdit) {
      places.forEach((place, i) => {
        placeList[i + 1] = place;
      });
      setNewMap({ ...newMap, places: placeList });

      return;
    }

    if (isShovel) {
      map[x][y] = isSea ? 'empty' : -1;
      setNewMap({ ...newMap, map });
    } else {
      if (isSea) {
        if (places.length < 8) {
          const rotate = getRotate([x, y], newMap.shape);
          places.push({
            ...basePlayer,
            coords: [x, y],
            rotate: rotate,
            position: places.length + 1,
            isActive: false,
          });

          setRotate(rotate || 0);

          places.forEach((place, i) => {
            placeList[String(i + 1)] = place;
          });

          setNewMap({ ...newMap, places: placeList });
        }
      } else {
        setCoords([x, y]);
      }
    }
  }

  const deleteShip = () => {
    const places = Object.values(newMap.places).filter(place => place.position !== shipPosition);
    let placeList: IPlayers = {};

    places.forEach((player, i) => {
      placeList[i + 1] = {
        ...player,
        position: i + 1,
      };
    });

    setNewMap({ ...newMap, places: placeList });
    setShipPosition(0);
  }

  const getRotate = ([y, x]: number[], shape: number): number => {
    const maxY: number = Object.keys(newMap.map).length;
    const maxX: number = Object.keys(Object.values(newMap.map)[y]).length;

    const isTop = y < (maxY - y);

    let sides: {} = {
      south: maxY - y,
      nord: y,
      west: x,
      east: maxX - x,
    };

    if (shape === 6) {
      sides = isTop ? {
        nord: y,
        nordWest: x,
        nordEast: maxX - x,
      } : {
        south: maxY - y,
        southWest: x,
        southEast: maxX - x,
      };
    }

    //@ts-ignore
    const side = Object.keys(sides).sort((a, b) => sides[a] - sides[b])[0];

    return {
      south: 0,
      east: 90,
      nord: 180,
      west: 270,
      nordWest: 240,
      nordEast: 120,
      southWest: 300,
      southEast: 60,
    }[side] || 0;
  }

  const setField = (field: string) => {
    if (coords === null) {
      return;
    }
    const map = { ...newMap.map };
    const [x, y] = coords;
    map[x][y] = field;

    setNewMap({ ...newMap, map });
    setCoords(null);
  }

  return (
    <>
      {coords === null ? (
        <div>
          <FieldMapEditor
            rotate={rotate}
            players={newMap.places}
            map={{
              shape: newMap.shape,
              side: newMap.side,
              map: newMap.map,
            }}
            setCoords={coords => changeFields(coords)}
          />

          <ExitButton onClick={() => pathname !== '/maps' ? setActivePanel(PAGES.create.path) : setMap(emptyMap)}/>
          <SaveButton
            icon={EICON.choose}
            onClick={() => saveMap()}
          />
          {newMap.mapId !== 'empty' && (
            <DeleteButton onClick={() => {
              request<any, any>({
                path: 'maps',
                method: 'DELETE',
                data: {
                  mapId: newMap.mapId,
                },
                success: () => setMap(emptyMap),
              });
            }}/>
          )}

          <div className={styles.menu}>
            <div className={styles.earthshipbtns}>
              <div className={styles.shapebtns}>
                <HexButton
                  className={clsx(
                    styles.shapebtn,
                    { [styles.active]: newMap.shape === 6 },
                  )}
                  size={35}
                  onClick={() => generateNewMap({ shape: 6 })}
                />
                <SquareButton
                  className={clsx(
                    styles.shapebtn,
                    { [styles.active]: newMap.shape === 4 },
                  )}
                  size={35}
                  onClick={() => generateNewMap({ shape: 4 })}
                />
              </div>
              <ScaleButtons
                type={'plus'}
                className={styles.scalex}
                range={minMaxShape.get(newMap.shape) || [8, 20]}
                step={1}
                value={newMap.side}
                setValue={side => generateNewMap({ side })}
              />
              <div className={styles.shapebtns}>
                {shipPosition ? (
                  <div onClick={() => deleteShip()}>
                    <Icon
                      className={styles.deleteship}
                      name={EICON.shipwreck}
                      size={50}
                    />
                  </div>
                ) : (
                  <div onClick={() => setIsShovel(true)}>
                    <HandySvg
                      className={clsx(
                        styles.shovel,
                        styles.shovelbtn,
                        { [styles.activeshovel]: isShovel },
                      )}
                      src={shovel}
                      width={45}
                      height={45}
                    />
                  </div>
                )}
                <div onClick={() => {
                  setIsShovel(false);
                  doFilling(newMap);
                }}>
                  <HandySvg
                    className={clsx(
                      styles.shovelbtn,
                      { [styles.activeshovel]: !isShovel },
                    )}
                    src={icon}
                    width={35}
                    height={35}
                  />
                </div>
              </div>
              <ScaleButtons
                type={'multi'}
                className={styles.scalemulti}
                range={[1, 10]}
                step={1}
                value={newMap.freq || 8}
                setValue={freq => generateNewMap({ freq })}
              />
            </div>
          </div>
        </div>
      ) : (
        <ChooseField
          giveField={field => setField(field)}
          shape={newMap.shape}
          coords={coords}
        />
      )}
    </>
  );
}