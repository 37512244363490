import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { gameContext } from '../../../providers/GameProvider';
import { socketContext } from '../../../providers/SocketProvider';
import { tooltipContext } from '../../../providers/TooltipProvider';
import { IUnitProps } from './interfaces';
import styles from './style.module.sass';
import icons from './mouse.svg';
import dead from './skeleton.svg';
import { HandySvg } from 'handy-svg';

const colors = [
  '',
  'красного',
  'оранжевого',
  'желтого',
  'зеленого',
  'голубого',
  'синего',
  'фиолетового',
  'розового',
];

export function Unit({ id, size, style, gold, userId, avatar = false, isDead = false }: IUnitProps) {
  const [hover, setHover] = useState(0);
  const { units, rotate, activeShip, userShip, userUnits, isUserShipActive, setUnitHover } = useContext(gameContext);
  const { socketEmit } = useContext(socketContext);
  const { showTooltip, hideTooltip } = useContext(tooltipContext);
  const curUnit = units.find(unit => id === unit.id);
  const unitIsActive = units && units.find(unit => (
    unit.type === 'unit' && unit.isActive
  ));

  const setAllHover = (hover: number) => {
    setUnitHover(hover);
    setHover(hover)
  }

  const handleUnit = () => {
    if (isUserShipActive && userShip?.userId === curUnit?.userId && curUnit?.selectable) {
      const isActive = userUnits?.find(({ isActive, type }) => isActive && type === 'unit');
      if (!isActive) {
        socketEmit('activeUnit', id);
      } else if (curUnit?.isActive) {
        if (gold) {
          socketEmit('putMoney');
        } else {
          socketEmit('activeUnit', id);
        }
      }
    }
  }

  return (
    <div
      id={avatar ? `avatar${curUnit?.id}` : curUnit?.id}
      className={clsx(
        styles.unit,
        { [styles.hoverunit]: hover },
        { [styles.active]: curUnit && curUnit.isActive },
        { [styles.gold]: gold },
      )}
      style={{
        ...style,
        transform: `rotate(-${!avatar && rotate ? rotate : 0}deg)`,
        opacity: Number(avatar),
      }}
      onMouseOver={({ clientX, clientY }) => {
        if ((!unitIsActive && !isDead && activeShip?.userId === curUnit?.userId) || unitIsActive?.id === curUnit?.id) {
          setAllHover(activeShip && activeShip.userId === userId ? 1 : 2)
        }

        if (curUnit) {
          showTooltip(
            'unit',
            [clientX, clientY],
            `Пират ${colors[+curUnit.color]} игрока`,
            <div>имя игрока: {curUnit.username}</div>,
            gold ? 1 : 0,
          );
        }
      }}
      onMouseOut={() => {
        setAllHover(0);
        ///hideTooltip();
      }}
      onClick={() => handleUnit()}
    >
      {curUnit &&
        <HandySvg
          src={isDead ? dead : icons}
          className={clsx(
            styles[`unit${curUnit.color}` as keyof typeof styles],
            { [styles.jump]: (
              (!unitIsActive && !isDead && activeShip?.userId === curUnit?.userId) ||
              unitIsActive?.id === curUnit.id
            )},
          )}
          width={size * (isDead ? 0.8 : 1.1)}
          height={size * (isDead ? 0.8 : 1.1)}
        />
      }
      {!!gold && <div
        className={styles.money}
        style={{
          width: `${size * 0.4}px`,
          height: `${size * 0.4}px`,
          borderRadius: `${size * 0.5}px`,
        }}
      />}
    </div>
  );
}
