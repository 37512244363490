import React, { useContext, useEffect } from 'react';
import { useLocation } from "react-router";
import { Routes, Route } from 'react-router-dom';
import { pagesContext } from "../providers/PagesProvider";
import Main from "./main/Main";
import Registration from "./registration/Registration";
import Authentification from "./authentification/Authentification";
import Page from "./page/Page";
import Vk from "./vk/Vk";
import { NotifyBox } from "./notifybox/NotifyBox";
import { PAGES } from "../providers/PagesProvider/pagesConfig";
import styles from './style.module.sass';
import Login from "./login/Login";
import Logout from "./logout/Logout";
import MapEditor from "./mapeditor/MapEditor";
import Welcome from "./welcome/Welcome";

export default function Pages() {
  const { pathname } = useLocation();
  const { setActivePanel } = useContext(pagesContext);

  useEffect(() => setActivePanel(pathname.substring(1) || PAGES.start.path), [pathname]);

  return (
    <div className={styles.pages}>
      <div className={styles.blur}>
      <Routes>
        <Route path="/registration" element={
          <Page key="registration" id="registration">
            <Registration />
          </Page>
        }></Route>
        <Route path="/authentification" element={
          <Page key="authentification" id="authentification">
            <Authentification />
          </Page>
        }></Route>
        <Route path="/login" element={
            <Login />
        }></Route>
        <Route path="/logout" element={
            <Logout />
        }></Route>
        <Route path="/" element={
          <Main />
        }></Route>
        <Route path="/vk" element={
          <Page key="vk" id="vk">
            <Vk />
          </Page>
        }></Route>
        <Route path="/maps" element={
          <Page key="maps" id={"maps"}>
            <MapEditor />
          </Page>
        }></Route>
        <Route path="/welcome" element={
          <Page key="welcome" id={"welcome"}>
            <Welcome />
          </Page>
        }></Route>
        {/*<Route path="fields" element={*/}
        {/*  <Page key={"fields"} id={"fields"}>*/}
        {/*    <FieldEditor />*/}
        {/*  </Page>*/}
        {/*}></Route>*/}
      </Routes>
      <NotifyBox />
      </div>
    </div>
  );
}
