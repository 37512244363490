import React from 'react';
import styles from './style.module.sass';
import clsx from "clsx";
import { EColor } from "./color.enum";
import { ITextProps } from "./text-props.interface";

export function Text({ As = 'span', color = EColor.white, children, size, bold = false, className }: ITextProps) {
  return (
    <As className={clsx(styles[`s${size}`], styles[color], { [styles.bold]: bold }, styles.base, className)} >
      {children}
    </As>
  );
}
