import React from 'react';
import mountains from './img/mountains.png';
import forest from './img/forest.png';
import desert from './img/desert.png';
import steppe from './img/steppe.png';
import jungle from './img/jungle.png';

import styles from './style.module.sass';
import { between } from "../../lib/between";

const esurface = {
  mountains: mountains,
  forest: forest,
  desert: desert,
  steppe: steppe,
  jungle: jungle,
};

const asurface = ['mountains', 'forest', 'desert', 'steppe', 'jungle'];

export default function Surface({ surface, size, rotate }: { surface: string | number, size: number, rotate: number }) {
  if (typeof surface === 'number' && !between(0, surface,4)) {
    const rem = Math.abs(surface % 10);
    surface = rem > 4 ? rem - 5 : rem;
  }

  return (
    <>
      <div
        className={styles.surface}
        style={{
          //@ts-ignore
          backgroundImage: `url(${esurface[typeof surface === 'number' ? asurface[surface] : surface]})`,
          backgroundSize: `${size}px ${size}px`,
          transform: `rotate(-${rotate}deg)`,
        }}
      />
    </>
  );
}
