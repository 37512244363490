import React, { useContext } from 'react';
import styles from './style.module.sass';
import { IUnitFieldItemProps } from './interfaces';
import { gameContext } from "../../../../providers/GameProvider";

export function UnitFieldItem({ inCenter, count, angle, size, translate, children, isEven, type }: IUnitFieldItemProps) {
  const { rotate } = useContext(gameContext);
  const deg = inCenter ? 0 : (angle * (count + 1) + +rotate + (isEven ? 0 : 30));

  return (
    <div
      className={styles.item}
      key={count}
      datatype={'unit-item'}
      style={{
        transform: inCenter ? '' : `
          rotate(${deg}deg)
          translate(${translate}px)
          rotate(-${deg}deg)
        `,
        width: size,
        height: size,
        margin: `-${size / 2}px`,
        borderRadius: size / 2,
      }}
    >
      {children}
    </div>
  );
}
