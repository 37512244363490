import { CSSProperties, ReactNode } from 'react';
import { getMapSize } from './getMapSize';
import { getFieldOffset } from './getFieldOffset';
import { IField } from '../interfaces/field.interface';
import { IOpenedMap } from '../interfaces/opened-map.interface';
import {generateRandomString} from "./generateRandomString";

interface IFieldMapProps {
  map: IOpenedMap,
  shape: number,
  size: number;
}

interface IChildProps {
  key: string;
  id: string;
  shape: number;
  coords: number[];
  size: number;
  placeStyle: CSSProperties;
}

type TCallBackFn = (
  value: IField,
  coords: number[],
  len: number[],
  childProps: IChildProps,
) => ReactNode;

export const fieldMapGenerator = ({ map, shape, size }: IFieldMapProps, fn: TCallBackFn) => {
  return Object.values(map).map((row, rowIdx) =>
    Object.values(row).map((col, colIdx) => fn(
        col,
        [rowIdx, colIdx],
        [Object.values(row).length, Object.values(map).length],
        {
          id: `field_id_${rowIdx}_${colIdx}`,
          key: `field_key_${rowIdx}_${colIdx}_${generateRandomString()}`,
          shape,
          coords: [rowIdx, colIdx],
          size: getMapSize(size, Object.values(map).length),
          placeStyle: getFieldOffset([rowIdx, colIdx], size, [Object.values(row).length, Object.values(map).length], shape),
        },
      )
    ))
};
