import React from 'react';
import { createUrl } from "../../../lib/createUrl";
import { EICON } from "../../Icon/eicon.enum";
import { Icon } from "../../Icon";
import styles from './style.module.sass';

export function VkAuthButton() {
  const url = createUrl('https://oauth.vk.com/authorize', {
    client_id: '51538224',
    display: 'page',
    redirect_uri: `https://${process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'making-web.ru'}`,
    scope: '22',
    state: 'vk_access_token_answer',
    response_type: 'token',
    v: '5.131',
  });

  return (
    <a className={styles.vkurl} href={url}>
      <Icon name={EICON.vk} size={80} />
    </a>
  );
}
