import React, { useContext, useState } from "react";
import bridge from '@vkontakte/vk-bridge';
import { usePageMount } from "../../hooks/usePageMount";
import { userContext } from "../../providers/UserProvider";
import { requestContext } from "../../providers/RequestProvider";
import { notifyContext } from "../../providers/NotifyProvider";
import Welcome from "./Welcome/Welcome";
import { VkAuthButton } from "../../ui/Buttons/VkAuthButton";
import { Button } from "../../ui/Buttons/Button";
import { Icon } from "../../ui/Icon";
import { PAGES } from "../../providers/PagesProvider/pagesConfig";
import { EICON } from "../../ui/Icon/eicon.enum";
import { IUser } from "../../interfaces/user.interface";
import { IGoogleAuthFormFields, IVkAuthFormFields } from "../vk/interfaces";
import styles from './style.module.sass';
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { Link } from "react-router-dom";

export default function Start() {
  const { getToken, setToken, getUserId, setUserId } = useContext(userContext);
  const { request } = useContext(requestContext);
  const { removeNotify } = useContext(notifyContext);
  const [name, setName] = useState('');
  const [score, setScore] = useState({ won: 0, played: 0, gold: 0, found: 0 });
  const [vkapp, setVkapp] = useState(false);
  const [googleapp, setGoogleapp] = useState(false);
  const token = getToken();
  const userId = getUserId();

  usePageMount(() => {
    bridge.send('VKWebAppGetUserInfo')
      .then(data => {
        setVkapp(true);
        request<IVkAuthFormFields, any>({
          data: {
            userId: data.id,
            accessToken: '',
            expiresIn: 86400,
            email: '',
            username: `${data.first_name} ${data.last_name}`,
            vkapp: vkapp,
          },
          method: 'POST',
          path: 'auth/vk',
          success: (data: IUser) => {
            if (data) {
              if (data.userId) {
                setUserId(data.userId);
              }
              if (data.accessToken && data.expiresIn) {
                setToken(data.accessToken, +data.expiresIn);
              }
              if (data.username) {
                setName(data.username);
              }
            }
          },
        });
      })
      .catch(response => {
        console.log('parsing failed', response)
      })
      .finally(() => {
        request<any, any>({
          path: 'auth/profile',
          success: (data: IUser) => {
            setName(data.username)
            setScore({
              won: data.won,
              played: data.played,
              gold: data.gold,
              found: data.found,
            });
          },
        });
      });

    setTimeout(() => {
      removeNotify();

      if (userId && token && !name) {
        request<any, any>({
          path: 'auth/profile',
          success: (data: IUser) => {
            setName(data.username)
            setScore({
              won: data.won,
              played: data.played,
              gold: data.gold,
              found: data.found,
            });
          },
        });
      }
    }, 3000);

  }, PAGES.start.path);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
          }
        })
        .then(({ data }) => {
          setGoogleapp(true);
          request<IGoogleAuthFormFields, any>({
            data: {
              userId: data.id,
              accessToken: codeResponse.access_token,
              expiresIn: codeResponse.expires_in,
              email: data.email,
              username: `${data.given_name} ${data.family_name}`,
              googleapp: googleapp,
            },
            method: 'POST',
            path: 'auth/google',
            success: (data: IUser) => {
              if (data) {
                if (data.userId) {
                  setUserId(data.userId);
                }
                if (data.accessToken && data.expiresIn) {
                  setToken(data.accessToken, +data.expiresIn);
                }
                if (data.username) {
                  setName(data.username);
                }
              }
            },
          });
        })
        .catch((err) => console.log(err))
        .finally(() => {
          request<any, any>({
            path: 'auth/profile',
            success: (data: IUser) => {
              setName(data.username)
              setScore({
                won: data.won,
                played: data.played,
                gold: data.gold,
                found: data.found,
              });
            },
          });
        });
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <div className={styles.startscreen}>
      {(token || name || vkapp || googleapp) ? (
          <Welcome
            vkapp={vkapp}
            userName={name || 'Нет имени'}
            played={score.played}
            won={score.won}
            gold={score.gold}
            found={score.found}
          />
        ) : (
          <div className={styles.authbtns}>
            {process.env.NODE_ENV === 'development' && (
              <Link to="/authentification">
                <Button>
                  <Icon name={EICON.login} size={100} />
                </Button>
              </Link>
            )}
            <VkAuthButton />
            <Button onClick={() => login()}>
              <Icon name={EICON.google} size={50} />
            </Button>
          </div>
        )}
    </div>
  );
}
