import React, { createContext, useContext, useEffect, useState } from 'react';
import { IProvidersProps } from "../interfaces/providers.interface";
import { INotify, INotifyContextProps } from "../interfaces/notify-provider.interface";
import { socketContext } from "../SocketProvider";

export const notifyContext = createContext<INotifyContextProps>({
  notify: [],
  setNotify: () => {},
  removeNotify: () => {},
});

export function NotifyProvider({ children }: IProvidersProps) {
  const { Provider } = notifyContext;
  const { socketEmit, isConnected } = useContext(socketContext);
  const [notify, setNotify] = useState<INotify[]>([]);

  const removeNotify = (id?: string) => {
    setNotify(notify => notify.filter(({ notifyId }) => id !== notifyId));
    socketEmit('removeNotify', { notifyId: id });
  };

  useEffect(() => {
    if (!isConnected) {
      setNotify([]);
    }
  }, [isConnected])

  return (
    <Provider value={{ notify, setNotify, removeNotify }}>
      {children}
    </Provider>
  );
}
