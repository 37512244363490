import React, { useContext } from 'react';
import clsx from 'clsx';
import { calculateHexOuter } from "../../../lib/calculateHexOuter";
import { tooltipContext } from "../../../providers/TooltipProvider";
import { IFieldButtonProps } from "./interfaces";
import styles from './style.module.sass';

export function FieldButton({
  style,
  children = '',
  className = '',
  placeClass = '',
  placeStyle = {},
  size = 100,
  onClick,
  shape,
  isAllowed = false,
  name = '',
  gold = 0,
  description = '',
  id,
}: IFieldButtonProps) {
  const width = size;
  const height = shape === 4 ? size : calculateHexOuter(size || 100);
  const { showTooltip, hideTooltip } = useContext(tooltipContext);

  return (
    <div
      id={id}
      style={{
        ...placeStyle,
        width: width,
        height: height,
      }}
      className={clsx(
        styles.shadow,
        { [styles.shadowhover]: isAllowed },
        { [styles.hex]: shape === 6 },
        { [styles.square]: shape === 4 },
        { [styles.allowedfield]: isAllowed },
        placeClass,
      )}
      onMouseMove={({ clientX, clientY }) => name && showTooltip(
        'field',
        [clientX, clientY],
        name || '',
        <>{description}</>,
        gold
      )}
      onMouseOut={() => false && hideTooltip()}
    >
      <div
        style={{
          ...style,
          width: width - 3,
          height: height - 3,
        }}
        className={clsx(
          styles.field,
          { [styles.hex]: shape === 6 },
          { [styles.square]: shape === 4 },
          className,
        )}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
}
