import React, { useEffect, useState } from 'react';
import { Field } from "../../../ui/Map/Field";
import { MenuMap } from "../../../ui/MenuMap";
import { useRequest } from "../../../hooks/useRequest";

const squareCoords = {
  gold1: [-2, 2],
  gold2: [-1, 1],
  gold3: [0, 0],
  gold4: [1, -1],
  gold5: [2, -2],
  labyrinth2: [-1, 2],
  labyrinth3: [0, 1],
  labyrinth4: [1, 0],
  labyrinth5: [2, -1],
  arrow1: [-2, 1],
  arrow2: [-1, 0],
  arrow3: [0, -1],
  arrow4: [1, -2],
  airplane: [-2, 0],
  balloon: [-1, -1],
  gun: [0, -2],
  cannibal: [0, 2],
  crocodile: [1, 1],
  trap: [2, 0],
  ice: [-2, -1],
  horses: [-1, -2],
  fort: [1, 2],
  woman: [2, 1],
  empty: [-2, -2],
  rum: [2, 2],
  cave: [0, -3],
  jungle: [0, 3],
};

const hexCoords = {
  gold1: [-1, 0],
  gold2: [-1, 1],
  gold3: [1, 1],
  gold4: [1, 0],
  gold5: [0, 0],
  labyrinth2: [-2, 0],
  labyrinth3: [-3, 1],
  labyrinth4: [-3, 2],
  labyrinth5: [-2, 2],
  arrow1: [2, 0],
  arrow2: [3, 1],
  arrow3: [3, 2],
  arrow4: [2, 2],
  airplane: [2, -1],
  balloon: [0, -2],
  gun: [2, 3],
  cannibal: [-1, 3],
  crocodile: [-2, 3],
  trap: [1, 3],
  ice: [-2, -1],
  horses: [-1, -2],
  fort: [0, 1],
  woman: [0, -1],
  empty: [0, 2],
  rum: [1, -2],
  cave: [0, -3],
  jungle: [0, 3],
};

const mapPos = new Map([
  [4, squareCoords],
  [6, hexCoords],
]);

export default function ChooseField({ shape, giveField, coords }: { shape: number, giveField: (field: string) => void, coords: number[] }) {
  const { request } = useRequest();
  const [fields, setFields] = useState([]);

  const mapWidth = Math.round(Math.min(window.innerWidth, window.innerHeight) / 120) * 100;

  useEffect(() => {
    if (coords && fields.length < 10) {
      request<any, any>({
        path: `fields`,
        method: 'GET',
        success: (fields) => {
          //@ts-ignore
          const newField = fields.map(field => ({
            ...field,
            shape,
            coords,
            //@ts-ignore
            position: mapPos.get(shape)[field.code],
          }));
          setFields(newField);
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  return (
    <MenuMap
      maps={fields}
      mapSize={mapWidth}
      idName={"code"}
      buttons={Field}
      fieldClick={(field: string) => giveField(field)}
      shape={shape}
      createBtns={() => {}}
      createBtnsClick={() => {}}
    />
  );
};