import React from 'react';
import styles from './style.module.sass';
import { IOnOffProps } from "./interfaces";

export function OnOff({ isConnected = false, allowTimer = false, className = '', length = 30 }: IOnOffProps) {

  return (
    <div
      className={`${styles.onoff} ${className}`}
      style={{ backgroundColor: isConnected ? 'green' : (allowTimer ? 'transparent' : 'red') }}
    />
  );
}
