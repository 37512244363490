export const PAGES = {
  start: {
    path: 'start',
    header: 'Начальный экран',
  },
  create: {
    path: 'create',
    header: 'Создать игру',
  },
  choose: {
    path: 'choose',
    header: 'Выбрать игру',
  },
  play: {
    path: 'play',
    header: 'Игра',
  },
  edit: {
    path: 'edit',
    header: 'Редактировать',
  }
};

export enum ESHAPES {
  hexagon = 'hexagon',
  square = 'square',
};