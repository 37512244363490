import React from 'react';
import clsx from 'clsx';
import { emptyFn } from '../../../lib/emptyFn';
import { IButtonProps } from './button.interface';
import styles from './style.module.sass';

export function Button({
  type = 'button', children, onClick = emptyFn, className = undefined,
}: IButtonProps) {

  return (
    <button type={type} onClick={onClick} className={clsx(styles.btn, className)}>
      {children}
    </button>
  );
}
