import React, { useState } from 'react';
import MapList from "./MapList/MapList";
import ChangeMap from "./ChangeMap/ChangeMap";
import { IMapEdit } from "./interfaces/imapedit.interface";

import styles from './style.module.sass';

export const emptyMap: IMapEdit = {
  mapId: '',
  shape: 4,
  side: 8,
  map: {},
  freq: 8,
  places: {},
  filling: [],
  position: [],
};

export default function MapEditor() {
  const [editMode, setEditMode] = useState(false);
  const [map, setMap] = useState<IMapEdit>(emptyMap);

  const setEditMap = (map: IMapEdit) => {
    setEditMode(map.mapId !== '');
    setMap(prev => ({
      ...prev,
      ...map,
    }));
  }

  return (
    <div className={styles.mapeditor}>
      {editMode ? (
        <ChangeMap
          map={map}
          setMap={setEditMap}
          editMode={editMode}
        />
      ) : (
        <MapList
          editMode={editMode}
          setMap={setEditMap}
        />
      )}
    </div>
  );
}
