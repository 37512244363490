import React, { useEffect, useRef } from 'react';
import Scrollbars from "react-custom-scrollbars-2";
import { ScaleButtons } from "../Buttons/ScaleButtons";
import styles from './style.module.sass';
import { IScale } from "./interfaces";

export function Scale({ size = 800, giveSize, range = [200, 4000], step = 100, children }: IScale) {
  const scrollbar = useRef(null);
  const fullSize = size * Math.sqrt(3);

  useEffect(() => {
    const scroll: any = scrollbar?.current;
    if (scroll) {
      scroll.scrollLeft((scroll.getScrollWidth() - scroll.getClientWidth()) / 2);
      scroll.scrollTop((scroll.getScrollHeight() - scroll.getClientHeight()) / 2);
    }
  },[size]);

  return (
    <div className={styles.scale}>
      <Scrollbars
        ref={scrollbar}
      >
        <div
          className={styles.scroll}
          id={'scrollbar'}
          style={{
            width: fullSize,
            height: fullSize,
            minHeight: '100vh',
          }}
        >
          {children}
        </div>
      </Scrollbars>
      <ScaleButtons
        value={size}
        setValue={giveSize}
        step={step}
        range={range}
      />
    </div>
  );
}
