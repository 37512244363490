import { useLocation } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { requestContext } from "../../providers/RequestProvider";

export default function Welcome() {
  const { state } = useLocation();
  const { request } = useContext(requestContext);
  const [welcome, setWelcome] = useState('');

  useEffect(() => {
    //@ts-ignore
    request({
      method: 'GET',
      path: 'update',
      success: data => {
        //@ts-ignore
        setWelcome(data.message);
      },
    });
  }, [state]);

  return (<>{welcome}</>);
}
