import React, { useContext } from 'react';
import { pagesContext } from "../../providers/PagesProvider";
import { IPageProps } from "./interfaces";
import styles from './style.module.sass';

export default function Page({ children, id }: IPageProps) {
  const { activePanel } = useContext(pagesContext);

  return (
    <>
      {activePanel === id
        ? <div data-id={'page'} className={styles.page}>{children}</div>
        : ''}
    </>
  );
}
