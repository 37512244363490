import React, { createContext, useContext, useEffect } from 'react';
import { useRequest } from '../../hooks/useRequest';
import { emptyFn } from '../../lib/emptyFn';
import { Loader } from "../../ui/Loader";
import { modalContext } from '../ModalProvider';
import { IRequestContextProps } from '../interfaces/request-provider.interface';
import { IProvidersProps } from '../interfaces/providers.interface';

export const requestContext = createContext<IRequestContextProps>({
  request: emptyFn,
  loading: false,
});

export function RequestProvider({ children }: IProvidersProps) {
  const { Provider } = requestContext;
  const { request, loading } = useRequest();
  const { showModal, hideModal } = useContext(modalContext);

  useEffect(() => loading ? showModal(<Loader />) : hideModal(), [loading]);

  return (
    <Provider value={{ request }}>
      {children}
    </Provider>
  );
}
