import { useLocation, useNavigate } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { userContext } from '../../providers/UserProvider';

export default function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setUserId, setToken } = useContext(userContext);

  useEffect(() => {
    if (
      state.userId &&
      state.username &&
      state.accessToken &&
      state.expiresIn
    ) {
      setUserId(state.userId);
      setToken(state.accessToken, state.expiresIn * 1000);
    }

    navigate('/');
  }, [state]);

  return (<></>);
}
