import React, { createContext, ReactElement, useState } from 'react';
import { IModalContextProps } from "../interfaces/modal-provider.interface";
import { IProvidersProps } from "../interfaces/providers.interface";
import { emptyFn } from "../../lib/emptyFn";

export const modalContext = createContext<IModalContextProps>({
  content: <></>,
  show: false,
  showModal: emptyFn,
  hideModal: emptyFn,
});

export function ModalProvider({ children }: IProvidersProps) {
  const { Provider } = modalContext;
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(<></>);

  const showModal = (content: ReactElement) => {
    setContent(content);
    setShow(true);
  }
  const hideModal = () => {
    setContent(<></>);
    setShow(false);
  }

  return (
    <Provider value={{ show, content, showModal, hideModal }}>
      {children}
    </Provider>
  );
}
