export const enum EICON {
  hexagon = 'hexagon',
  square = 'square',
  close = 'close',
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  login = 'login',
  signin = 'signin',
  exit = 'exit',
  shipwreck = 'shipwreck',
  save = 'save',
  vk = 'vk',
  arrowRight = 'arrowRight',
  plus = 'plus',
  filling = 'filling',
  delete = 'delete',
  choose = 'choose',
  google = 'google',
  shovel = 'shovel',
}
