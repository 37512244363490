import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { Form } from '../../ui/Form';
import { Icon } from '../../ui/Icon';
import { requestContext } from "../../providers/RequestProvider";
import { RegistrationStatus } from './interfaces/registration-status.interface';
import { IRegFormFields } from "./interfaces/reg-form-fields.interface";
import { EICON } from "../../ui/Icon/eicon.enum";
import styles from './style.module.sass';

const fields = {
  username: {
    id: 'username',
    label: 'Имя',
    name: 'username',
    regex: /^[А-Яа-яA-Za-z0-9\-_.!@#$%^&* ]{3,50}$/g,
    error: 'Использованы недопустимые символы либо длина не в диапазоне от 3 до 50 символов',
    type: 'text',
  },
  email: {
    id: 'email',
    label: 'Email',
    name: 'email',
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    error: 'Введен не корректный Email адрес',
    type: 'email',
  },
  password: {
    id: 'password',
    label: 'Пароль',
    name: 'password',
    regex: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,50}$/,
    error: 'Пароль должен содержать хотя бы одну заглавную и одну строчную букву, цифру, и спецсимвол. ' +
      'Длина пароля от 10 до 50 символов',
    type: 'password',
  },
}

export default function Registration() {
  const navigate = useNavigate();
  const [values, setValues] = useState<IRegFormFields>({});
  const { request } = useContext(requestContext);

  useEffect(() => {
    if (values.username && values.password && values.email) {
      request<IRegFormFields, any>({
        data: values,
        method: 'POST',
        path: 'auth/register',
        success: (data: RegistrationStatus) => {
          if (data && data.success) {
            navigate('/login', {
              state: values,
            });
          }
        },
      });
    }
  }, [values]);

  return (
    <Form
      className={styles.form}
      submitText={<Icon name={EICON.login} size={50} />}
      giveData={value => setValues(value as IRegFormFields)}
      fields={fields}
    />
  )
}
