import React, { useContext, useState } from 'react';
import { requestContext } from '../../../providers/RequestProvider';
import { pagesContext } from '../../../providers/PagesProvider';
import { userContext } from '../../../providers/UserProvider';
import { PAGES } from '../../../providers/PagesProvider/pagesConfig';
import { EICON } from '../../../ui/Icon/eicon.enum';
import { ExitButton } from '../../../ui/Buttons/ExitButton';
import { Link } from 'react-router-dom';
import { gameContext } from "../../../providers/GameProvider";
import { MenuMap } from "../../../ui/MenuMap";
import { Scale } from "../../../ui/Scale";
import { IWelcome } from "./interfaces/welcome.interface";
import { ScoreField } from "../../../ui/ScoreField/ScoreField";
import { PlayButton } from "../../../ui/Buttons/PlayButton/PlayButton";
import { declinationOfNumber } from "../../../lib/declinationOfNumber";
import { usePageMount } from "../../../hooks/usePageMount";

export default function Welcome({ userName, vkapp = false, won = 0, played = 0, gold = 0, found = 0 }: IWelcome) {
  const { removeGame } = useContext(gameContext);
  const { request } = useContext(requestContext);
  const { setActivePanel } = useContext(pagesContext);
  const { getUserId, setGameId, removeGameId } = useContext(userContext);
  const [size, setSize] = useState(800);
  const userId = getUserId();

  const percent = (won && played) ? Math.round(won / played * 100) : 0;
  const average = (gold && played) ? Math.round(gold / played) : 0;


  usePageMount(() => setSize(
    Math.round(Math.min(window.innerWidth, window.innerHeight, 800) / 100) * 100
  ), PAGES.start.path);

  const checkGame = () => {
    console.log(userId);
    if (userId) {
      request<any, any>({
        method: 'POST',
        path: 'game/check',
        data: { userId },
        success: (gameId: string | false) => {
          console.log('checkGame', gameId);
          if (gameId) {
            setGameId(gameId);
            setActivePanel(PAGES.play.path);
          } else {
            removeGame();
            removeGameId();
            setActivePanel(PAGES.choose.path);
          }
        },
      });
    } else {
      setActivePanel(PAGES.choose.path);
    }
  }

  return (
    <>
      {userName &&
        <>
          <Scale
            size={size}
            giveSize={setSize}
            step={100}
            range={[200, 2000]}
          >
            <MenuMap
              maps={[{
                code: 'played',
                name: declinationOfNumber(played,'сыграна', 'сыграно', 'сыграно'),
                unit: declinationOfNumber(played,'игра', 'игры', 'игр'),
                value: String(played),
                position: [-1, 0],
                color: '#4d003c',
              }, {
                code: 'found',
                name: declinationOfNumber(found,'найдена', 'найдено', 'найдено'),
                unit: declinationOfNumber(found,'монета', 'монеты', 'монет'),
                value: String(found),
                position: [-1, 1],
                color: '#300052',
              }, {
                code: 'won',
                name: declinationOfNumber(won,'одержана', 'одержаны', 'одержано'),
                unit: declinationOfNumber(won,'победа', 'победы', 'побед'),
                value: String(won),
                position: [0, -1],
                color: '#004f18',
              }, {
                code: 'gold',
                name: declinationOfNumber(gold,'добыта', 'добыты', 'добыто'),
                unit: declinationOfNumber(gold,'монета', 'монеты', 'монет'),
                value: String(gold),
                position: [0, 1],
                color: '#7a6100',
              }, {
                code: 'percent',
                name: 'итого',
                unit: 'побед',
                value: String(percent) + '%',
                position: [1, 0],
                color: '#002956',
              }, {
                code: 'average',
                name: 'в среднем',
                unit: declinationOfNumber(average,'монета за игру', 'монеты за игру', 'монет за игру'),
                value: String(average),
                position: [1, 1],
                color: '#863800',
              }]}
              mapSize={size}
              idName={'gameId'}
              fieldClick={() => {}}
              buttons={ScoreField}
              createBtns={PlayButton}
              createBtnsClick={() => checkGame()}
              fulling={100}
            />
          </Scale>
        </>
      }
      {!vkapp &&
        <Link to={'/logout'}>
          <ExitButton
            icon={EICON.shipwreck}
            onClick={() => {}}
          />
        </Link>
      }
    </>
  );
}
