import React from 'react';
import { calculateHexOuter } from "../../../lib/calculateHexOuter";
import { IHexButtonProps } from "./interfaces";
import styles from './style.module.sass';

export function HexButton({ style, children = '', className = '', placeClass = '', placeStyle = {}, size = 100, onClick }: IHexButtonProps) {
  return (
      <div
        style={{
          ...style,
          ...placeStyle,
          width: size,
          height: calculateHexOuter(size),
        }}
        className={`${styles.shadow} ${placeClass}`}
      >
        <div
          style={{
            ...style,
            width: size - 2,
            height: calculateHexOuter(size) - 2,
          }}
          className={`${styles.hex} ${className}`} onClick={onClick}
        >
          {children}
        </div>
      </div>
  );
}
