import React, { useContext, useState } from 'react';
import { usePageMount } from "../../hooks/usePageMount";
import { pagesContext } from "../../providers/PagesProvider";
import { requestContext } from "../../providers/RequestProvider";
import { userContext } from "../../providers/UserProvider";
import { socketContext } from "../../providers/SocketProvider";
import { Scale } from "../../ui/Scale";
import { MenuMap } from "../../ui/MenuMap";
import { CreateGameField } from "../../ui/CreateGameField";
import { ExitButton } from "../../ui/Buttons/ExitButton";
import { CreateNewGame } from "../../ui/CreateNewGame";
import { IconButton } from "../../ui/Buttons/IconButton";
import { PAGES } from "../../providers/PagesProvider/pagesConfig";
import { IMap } from "../../interfaces/map.interface";
import { EICON } from "../../ui/Icon/eicon.enum";
import styles from './style.module.sass';
import {useNavigate} from "react-router-dom";

export default function Create() {
  const navigate = useNavigate();
  const { activePanel, setActivePanel } = useContext(pagesContext);
  const { request } = useContext(requestContext);
  const { getUserId, getGameId } = useContext(userContext);
  const { socketEmit } = useContext(socketContext);
  const [maps, setMaps] = useState<IMap[]>([]);
  const [size, setSize] = useState(800);
  const [editMode, setEditMode] = useState<boolean>(false);
  const userId = getUserId();
  const gameId = getGameId();

  usePageMount(() => {
    setSize(Math.round(Math.min(window.innerWidth, window.innerHeight, 800) / 100) * 100);
    if (activePanel === 'create') {
      request<undefined, IMap[]>({
        path: 'maps',
        success: (data) => {
          console.log('maps', data);
          if (data && data.length) {
            setMaps(data);
          }
        },
      });
    }
  }, PAGES.create.path);

  const addMap = (mapId: string) => {
    request<any, string>({
      path: 'game/addmap',
      method: 'POST',
      data: { gameId, userId, mapId },
      success: (gameId) => {
        console.log('addgame', gameId);
        if (gameId) {
          socketEmit('games');
          setActivePanel(PAGES.play.path);
        }
      },
    });
  }

  const deleteGame = () => {
    request<any, string>({
      path: 'game/remove',
      method: 'POST',
      data: { gameId, userId },
      success: () => {
        socketEmit('games');
        setActivePanel(PAGES.choose.path);
      },
    });
  }

  return (
    <div className={styles.creategame}>
      <Scale
        size={size}
        giveSize={setSize}
        step={100}
        range={[200, 2000]}
      >
        {maps && (
          <MenuMap
            maps={maps}
            mapSize={size}
            idName={"mapId"}
            buttons={CreateGameField}
            fieldClick={(mapId: string) => addMap(mapId)}
            createBtns={CreateNewGame}
            createBtnsClick={() => setActivePanel(PAGES.edit.path)}
          />
        )}
      </Scale>
      <ExitButton
        icon={EICON.shipwreck}
        onClick={() => deleteGame()}
      />
      {userId === 'google_106396878193481978396' && <IconButton
        icon={EICON.shovel}
        size={50}
        onClick={() => navigate('/maps')}
        className={styles.editmode}
      />}
    </div>
  );
}
