import React, { useContext } from 'react';
import { socketContext } from '../../../../providers/SocketProvider';
import { gameContext } from "../../../../providers/GameProvider";
import { sameCoords } from "../../../../lib/sameCoords";
import clsx from "clsx";
import { IMoneyItemProps } from './interfaces';
import styles from './style.module.sass';

export function MoneyItem({ money, size, coords, isRotate = true }: IMoneyItemProps) {
  const { rotate, isUserShipActive, activeShip, userUnits } = useContext(gameContext);
  const { socketEmit } = useContext(socketContext);
  const isActiveField = userUnits && activeShip && userUnits.some(({ coords: [x, y], isActive }) => (
    isUserShipActive && sameCoords(coords, [x, y]) && isActive
  ));

  return (
    <div style={{ transform: `rotate(-${isRotate ? rotate : 0}deg)` }}>
      <div
        className={clsx(
          styles.moneyitem,
          { [styles.jump]: money },
        )}
        onClick={() => isActiveField && socketEmit('takeMoney')}
        style={{
          cursor: isActiveField ? 'pointer' : 'default',
          width: size * 0.7,
          height: size * 0.7,

        }}
      >
        <div
          className={styles.count}
          style={{
            fontSize: size / 2,
          }}
        >
          {money}
        </div>
      </div>
    </div>
  );
}
