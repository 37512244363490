import React, { useContext } from 'react';
import clsx from 'clsx';
import { IconButton } from "../../../ui/Buttons/IconButton";
import { ENotifyType } from '../../../providers/NotifyProvider/enotifytype.enum';
import { INotifyProps } from './interface';
import styles from './style.module.sass';
import { EICON } from "../../../ui/Icon/eicon.enum";
import { notifyContext } from "../../../providers/NotifyProvider";

export default function Notify({ header, children, type = ENotifyType.info, id }: INotifyProps) {
  const { removeNotify } = useContext(notifyContext);
  let icon = 'info';
  switch (type) {
    case ENotifyType.success: icon = 'success'; break;
    case ENotifyType.warning: icon = 'warning'; break;
    case ENotifyType.error: icon = 'error'; break;
    case ENotifyType.info: icon = 'info'; break;
  }

  return (
    <div className={clsx(styles.notify, styles[type])}>
      <IconButton size={20} icon={icon} />
      <div className={styles.textbox}>
        <h3>{header}</h3>
        <p>{children}</p>
      </div>
      <IconButton size={10} icon={EICON.close} onClick={() => removeNotify(id)} />
    </div>
  );
}
