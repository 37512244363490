import React, { ChangeEvent } from 'react';
import { ITextInputProps } from './interfaces';
import styles from './style.module.sass';

export function TextInput({ id, type = 'text', name = '', placeholder, onChange, value, range }: ITextInputProps) {
  return (
    <input
      id={id}
      className={styles.input}
      value={value}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(name, target.value)}
      min={['range', 'number'].includes(type) && Array.isArray(range) ? range[0] : undefined}
      step={type === 'range' && Array.isArray(range) ? range[1] : undefined}
      max={['range', 'number'].includes(type) && Array.isArray(range) ? range[2] : undefined}
      style={{ width: type !== 'radio' ? '100%' : 'auto' }}
    />
  );
}
