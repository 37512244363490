import React, { useContext, useState } from 'react';
import { usePageMount } from "../../hooks/usePageMount";
import { pagesContext } from "../../providers/PagesProvider";
import { userContext } from "../../providers/UserProvider";
import { socketContext } from "../../providers/SocketProvider";
import { PAGES } from "../../providers/PagesProvider/pagesConfig";
import { Scale } from "../../ui/Scale";
import { MenuMap } from "../../ui/MenuMap";
import { CreateGameField } from "../../ui/CreateGameField";
import { CreateNewGame } from "../../ui/CreateNewGame";
import { Button } from "../../ui/Buttons/Button";
import { OnOff } from "../../ui/OnOff";
import { GameWithMapInterface } from "../../interfaces/game-with-map.interface";
import styles from './style.module.sass';
import { requestContext } from "../../providers/RequestProvider";
import { modalContext } from "../../providers/ModalProvider";
import { Loader } from "../../ui/Loader";


export default function Choose() {
  const [games, setGames] = useState<GameWithMapInterface[]>();
  const { showModal, hideModal } = useContext(modalContext);
  const { request } = useContext(requestContext);
  const { setActivePanel } = useContext(pagesContext);
  const { getUserId, setGameId } = useContext(userContext);
  const { isConnected, socketConnect, socketOn, socketOff, socketEmit, socketId } = useContext(socketContext);
  const [size, setSize] = useState(800);
  const userId = getUserId();

  usePageMount(() => {
    showModal(<Loader />);
    socketConnect();
    setSize(Math.round(Math.min(window.innerWidth, window.innerHeight, 800) / 100) * 100);

    socketOn('games', (games: GameWithMapInterface[]) => {
      console.log('games', games);
      setGames(games);
      hideModal();
    });

    socketOn('allowjoin', (gameId) => {
      console.log('allowjoin', gameId);
      setGameId(gameId);
      setActivePanel(PAGES.play.path);
    });

    socketEmit('games');

    return () => {
      socketOff('games');
      socketOff('allowjoin');
    };
  }, PAGES.choose.path);

  const createNewGame = (args: any) => {
    if (args && args.position && args.position.length) {
      request<any, string>({
        path: 'game/create',
        method: 'POST',
        data: {
          userId: userId,
          position: args.position,
        },
        success: (gameId: string) => {
          if (gameId) {
            socketEmit('games');
            setGameId(gameId);
            setActivePanel(PAGES.create.path);
          }
        },
      });
    }
  }

  return (
    <div className={styles.choosegame}>
      <div>
        <Scale
          size={size}
          giveSize={setSize}
          step={100}
          range={[200, 2000]}
        >
          <MenuMap
            maps={games}
            mapSize={size}
            idName={'gameId'}
            fieldClick={(gameId: string) => socketEmit('allowjoin', {
              gameId: gameId,
              userId: userId,
              socketId: socketId,
            })}
            buttons={CreateGameField}
            createBtns={CreateNewGame}
            createBtnsClick={(args) => createNewGame(args)}
          />
        </Scale>
        <Button className={styles.onoff}>
          <OnOff isConnected={isConnected} />
        </Button>
      </div>
    </div>
  );
}
