import React from 'react';
import { FieldInterface } from "./field.interfaces";
import Surface from "../../Surface/Surface";
import IconContainer from "../../../pages/mapeditor/ChooseField/IconContainer/IconContainer";

export function Field({ code, shape, position, size = 50, rotate = 0 }: FieldInterface) {

  return (
    <>
      <Surface
        surface={position[0] + position[1]}
        size={size * 0.9}
        rotate={rotate}
      />
      <IconContainer
        rotate={rotate}
        code={code}
        shape={shape}
        size={size * 0.8}
      />
    </>
  );
}