import React, { useState } from 'react';
import { preventDefault } from "../../lib/preventDefault";
import { TextInput } from './TextInput';
import { Button } from '../Buttons/Button';
import { Text } from '../Text';
import { IFormProps, IFieldData, ISetValue, IValues } from './interfaces';
import styles from './style.module.sass';

export function Form({ fields, submitText, giveData, className, altCmp }: IFormProps) {
  const [data, setData] = useState<IFieldData>(fields);
  const [isTouch, setIsTouch] = useState(false);

  const setValue: ISetValue = (name, value) => (
    name in data && setData((prev: IFieldData) => ({
      ...prev, [name]: {
        ...prev[name], value, isError: !value || !value.match(prev[name].regex),
      },
    }))
  );

  const handleSubmit = () => {
    setIsTouch(true);
    if (Object.entries(data).every(([name, value]) => value.value && value.value.match(value.regex))) {
      let values: IValues = {};
      Object.entries(data).forEach(([name, value]) => {
          values[`${ name }`] = value.value || null;
      });
      giveData(values);
    }
  }

  return (
    <div className={className}>
      <form onSubmit={preventDefault(handleSubmit)}>
        {data && Object.entries(data).map(([key, {
          id,
          name,
          type,
          error,
          isError,
          value ,
          range,
          label,
        }]) => (
          <div key={id}>
            {type && ['range', 'number'].includes(type) && <label htmlFor={id}><Text size={28}>{label}: </Text></label>}
            {type && ['range'].includes(type) && <Text size={28}>{value}</Text>}
            <br/>
            <TextInput
              id={id}
              name={name}
              type={type}
              placeholder={label}
              onChange={setValue}
              value={value}
              range={range}
            />
            {type && ['radio'].includes(type) && <label htmlFor={id}><Text size={28}>{label}</Text></label>}
            <br/>
            {isTouch && isError && <span className={styles.error}>{error}</span>}<br/>
          </div>
        ))}
        <div className={styles.formbtn}>
          <Button type={'submit'}>{submitText}</Button>
          {altCmp}
        </div>

      </form>
    </div>
  );
}
