import React from 'react';
import clsx from 'clsx';
import { emptyFn } from "../../../lib/emptyFn";
import { Icon } from "../../Icon";
import { EICON } from "../../Icon/eicon.enum";
import styles from './style.module.sass';

export default function SaveButton({ icon = EICON.save, onClick = emptyFn }: { icon?:  keyof typeof EICON, onClick: () => void }) {
  return (
    <button type='button' onClick={onClick} className={clsx(styles.btn, styles.icon)}>
      <Icon name={icon} size={50} />
    </button>
  );
}
