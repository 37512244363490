import React from 'react';
import clsx from 'clsx';
import { Icon } from '../../Icon';
import styles from './style.module.sass';

interface IIconBtnProps {
  size?: number;
  style?: object;
  icon: string;
  onClick?: () => void;
  className?: string;
}

export function IconButton({ size = 20, icon = '', onClick = () => {}, className = '', style }: IIconBtnProps) {
  return (
    <button
      style={style}
      className={clsx(styles.iconBtn, className)}
      onClick={onClick}
    >
      <Icon name={icon} size={size} />
    </button>
  );
}
