import React, { useEffect, useState } from 'react';
import { useRequest } from "../../../hooks/useRequest";
import { MenuMap } from "../../../ui/MenuMap";
import { CreateGameField } from "../../../ui/CreateGameField";
import { CreateNewGame } from "../../../ui/CreateNewGame";
import { Scale } from "../../../ui/Scale";
import { IMap } from "../../../interfaces/map.interface";
import { IMapEdit } from "../interfaces/imapedit.interface";
import { IMapListProps } from "./maplist.interface";
import { emptyMap } from "../MapEditor";
import { generateSquareMap } from "../../../lib/generateSquareMap";

export default function MapList({ editMode, setMap }: IMapListProps) {
  const { request } = useRequest();
  const [maps, setMaps] = useState<IMap[]>([]);
  const [size, setSize] = useState(Math.round(Math.min(window.innerWidth, window.innerHeight) / 120) * 100);

  useEffect(() => {
    if (!editMode) {
      request<any, any>({
        path: 'maps',
        success: (maps) => setMaps(maps),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const chooseMap = (mapId: string, position?: number[]) => {
    const newMap: IMapEdit = { ...emptyMap };

    if (mapId === 'empty' && position && position.length) {
      newMap.mapId = 'empty';
      newMap.position = position;
      newMap.map = generateSquareMap(8);
    }

    const map = maps.find(map => mapId === map.mapId);
    if (map) {
      newMap.mapId = map.mapId;
      newMap.shape = map.shape;
      newMap.side = map.side;
      newMap.places = map.places;
      newMap.map = map.map;
      newMap.filling = map.filling;
      newMap.position = map.position;
    }

    setMap(newMap);
  }

  return (
    <>
      <Scale
        size={size}
        giveSize={setSize}
        step={100}
        range={[200, 2000]}
      >
        {maps && (
          <MenuMap
            maps={[...maps]}
            mapSize={size}
            idName={"mapId"}
            buttons={CreateGameField}
            fieldClick={(mapId: string) => mapId !== 'empty' && chooseMap(mapId)}
            createBtns={CreateNewGame}
            createBtnsClick={args => args && args.position && chooseMap('empty', args.position)}
          >
          </MenuMap>
        )}
      </Scale>

    </>
  );
}