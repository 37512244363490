import React, { createContext } from 'react';
import { IUserContextProps } from '../interfaces/user-provider.interface';
import { IProvidersProps } from '../interfaces/providers.interface';

export const userContext = createContext<IUserContextProps>({
  setUserId: () => {},
  getUserId: () => null,
  removeUserId: () => {},
  setToken: () => {},
  getToken: () => null,
  removeToken: () => {},
  setGameId: () => {},
  getGameId: () => null,
  removeGameId: () => {},
});

export function UserProvider({ children }: IProvidersProps) {
  const { Provider } = userContext;

  const setUserId = (userId: string) => {
    localStorage.setItem('userId', userId);
  }

  const getUserId = (): string | null => {
    return localStorage.getItem('userId');
  };

  const removeUserId = (): void => {
    localStorage.removeItem('userId');
  }

  const setGameId = (gameId: string) => {
    localStorage.setItem('gameId', gameId);
  }

  const getGameId = (): string | null => {
    return localStorage.getItem('gameId');
  };

  const removeGameId = (): void => {
    localStorage.removeItem('gameId');
  }

  const setToken = (token: string, expiresIn: number): void => {
    localStorage.setItem(
      'token',
      JSON.stringify({
        value: token,
        expiresIn: new Date(Date.now() + expiresIn).getTime(),
      })
    );
  };

  const getToken = (): string | null => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const token = JSON.parse(storedToken);
      if (token.expiresIn > Date.now()) {
        return token.value;
      }
    }
    removeToken();

    return null;
  };

  const removeToken = (): void => {
    localStorage.removeItem('token');
  };

  return (
    <Provider value={{
      setToken, getToken, removeToken,
      setUserId, getUserId, removeUserId,
      setGameId, getGameId, removeGameId,
    }}>
      {children}
    </Provider>
  );
}
