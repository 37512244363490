import React from 'react';
import { ISquareButtonProps } from './interfaces';
import styles from './style.module.sass';

export function SquareButton({ style, children, size = 100, className, placeClass = '', placeStyle = {}, onClick }: ISquareButtonProps) {
  style = {
    ...style,
    width: size,
    height: size,
  };

  return (
      <div style={{ ...style, ...placeStyle }} className={`${styles.shadow} ${placeClass}`}>
        <div style={style} className={`${styles.square} ${className}`} onClick={onClick}>
          {children}
        </div>
      </div>
  );
}
