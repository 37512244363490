import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { tooltipContext } from "../../providers/TooltipProvider";

export default function Tooltipe() {
  const { show, content, coords: [x, y] } = useContext(tooltipContext);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    if (ref.current.style) {
      ref.current.style.display = show ? 'inline-block' : 'none';
      ref.current.style.left = `${x - 94}px`;
      ref.current.style.top = `${y + 30}px`;
    }
  }, [show]);

  if (!ref) return null;

  return (
    <div ref={ref}
      style={{
        width: '200px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'absolute',
        textShadow: `0px 0px 4px white, 0px 0px 6px white, 0px 0px 8px white, 0px 0px 10px white`,
      }}
    >
      {content}
    </div>
  );
}
