import { IGameOver } from "./interface/game-over.interface";
import { Icon } from "../Icon";
import { EICON } from "../Icon/eicon.enum";
import { Button } from "../Buttons/Button";
import React, { useContext } from "react";
import { PAGES } from "../../providers/PagesProvider/pagesConfig";
import { pagesContext } from "../../providers/PagesProvider";
import { userContext } from "../../providers/UserProvider";
import { gameContext } from "../../providers/GameProvider";
import { declinationOfNumber } from "../../lib/declinationOfNumber";

interface IGameOverText {
  title: string;
  goldText: string;
  unitText: string;
  color: string;
}

const gameOverText = new Map<boolean, IGameOverText>([
  [true, {
    title: 'Поздравляем, вы победили!',
    goldText: 'Вы собрали большее количество монет!',
    unitText: 'Противник бежал или был полностью разгромлен!',
    color: 'lime',
  }],
  [false, {
    title: 'К сожалению вы проиграли!',
    goldText: 'К сожалению вы собрали меньше монет чем соперник!',
    unitText: 'Увы! У вас не осталось пиратов!',
    color: 'red',
  }],
]);

const goldCountText = (count: number) => (
  `Вы добыли ${String(count)} ${declinationOfNumber(count,'монету', 'монеты', 'монет')}!`
);

export default function GameOver({ isWinner, gold = 0, type, rating }: IGameOver) {
  const { removeGame } = useContext(gameContext);
  const { removeGameId } = useContext(userContext);
  const { setActivePanel } = useContext(pagesContext);
  const { title, goldText, unitText, color } = gameOverText.get(isWinner) as IGameOverText;

  return (
    <div style={{
      color: 'white',
      textAlign: 'center',
      textShadow: `0px 0px 4px black, 0px 0px 6px black, 0px 0px 8px ${color}, 0px 0px 10px ${color}`,
    }}>
      <h1>{title}</h1>
      <div>{type === 'gold' ? goldText : unitText}</div>
      <div>{goldCountText(gold || 0)}</div>
      {rating && rating.map(({ username, gold }, i) => <div key={i} >{username}: {gold}</div>)}
      <Button
        onClick={() => {
          removeGame();
          removeGameId();
          setActivePanel(PAGES.start.path);
        }}
      >
        <Icon name={EICON.arrowRight} size={100} />
      </Button>
    </div>
  );
}