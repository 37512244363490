import React from 'react';
import { IScoreFieldProps } from './score.interface';
import styles from './style.module.sass';
import { ScoreFieldLetter } from "./ScoreFieldLetter/ScoreFieldLetter";

export function ScoreField({ name = '', unit = '', value = '', size = 10, color }: IScoreFieldProps) {
  return (
    <div className={styles.field}>
        <div className={styles.top}>
          {name.split('').reverse().map((letter, i, arr) => (
            <ScoreFieldLetter key={i} len={arr.length} size={size} count={i}>
              {letter}
            </ScoreFieldLetter>
          ))}
        </div>
        <div className={styles.score} style={{
          fontSize: `${size / 3}px`,
          textShadow: `0px 0px 4px ${color}, 0px 0px 6px ${color}, 0px 0px 8px ${color}, 0px 0px 10px ${color}`,
        }}>
          {value}
        </div>
        <div className={styles.bottom}>
          {unit.split('').reverse().map((letter, i, arr) => (
            <ScoreFieldLetter key={i} len={arr.length} size={size} count={i} isConcave={true}>
              {letter}
            </ScoreFieldLetter>
          ))}
        </div>
    </div>
  );
}