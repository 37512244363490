import React from 'react';
import { repeat } from '../../lib/repeat';
import { ICircleProps } from './interfaces';
import styles from './style.module.sass';

export function Circle({ children, count = 1, size, occupied = 0, uSize = 0 }: ICircleProps) {
  const unitSize = uSize || (size / 8);
  const unitTranslate = size / 3;
  const angle = 360 / (count || 1);

  return (
    <div className={styles.circle} style={{ width: size, height: size }}>
      {repeat(+count, i => React.cloneElement(children, {
        key: i,
        value: `${i}`,
        style: {
          position: 'absolute',
          transform: `rotate(${angle * (i + 1)}deg) translate(${unitTranslate}px) rotate(-${angle * (i + 1)}deg)`,
          width: `${unitSize}px`,
          height: `${unitSize}px`,
          margin: `-${unitSize / 2}px`,
        },
        className: (occupied > i) ? styles[`ship${i + 1}` as keyof typeof styles] : '',
      }))}
    </div>
  );
}
