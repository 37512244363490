import React from 'react';
import clsx from "clsx";
import { HandySvg } from "handy-svg";
import { repeat } from "../../../../lib/repeat";
import { UnitFieldItem } from "../../../../ui/Map/UnitField/UnitFieldItem";
import { LabyrinthItem } from "../../../../ui/Map/UnitField/LabyrinthItem";
import { MoneyItem } from "../../../../ui/Map/UnitField/MoneyItem";

import styles from "./style.module.sass";

import crocodile from "./img/crocodile.svg";
import airplane from "./img/airplane.svg";
import cannibal from "./img/cannibal.svg";
import balloon from "./img/balloon.svg";
import horses from "./img/horses.svg";
import jungle from "./img/jungle.svg";
import arrow from "./img/arrow.svg";
import woman from "./img/woman.svg";
import cave from "./img/cave.svg";
import trap from "./img/trap.svg";
import fort from "./img/fort.svg";
import gold from "./img/gold.svg";
import ice from "./img/ice.svg";
import rum from "./img/rum.svg";
import gun from "./img/gun.svg";

const eicon = {
  crocodile: crocodile,
  airplane: airplane,
  cannibal: cannibal,
  balloon: balloon,
  horses: horses,
  jungle: jungle,
  arrow: arrow,
  woman: woman,
  gold1: gold,
  gold2: gold,
  gold3: gold,
  gold4: gold,
  gold5: gold,
  cave: cave,
  trap: trap,
  fort: fort,
  ice: ice,
  rum: rum,
  gun: gun,
}

const dirs = new Map([
  [4, {
    arrow1: [[315], [0], [45], [270], [90], [225], [180], [135]],
    arrow2: [[315, 135], [0, 180], [45, 225], [270, 90]],
    arrow3: [[0, 225, 135], [315, 90, 225], [315, 45, 180], [45, 270, 135]],
    arrow4: [[315, 45, 225, 135], [0, 270, 90, 180]],
  }],
  [6, {
    arrow1: [[330], [30], [270], [90], [210], [150]],
    arrow2: [[330, 150], [30, 210], [270, 90]],
    arrow3: [[330, 90, 210], [30, 270, 150]],
    arrow4: [[330, 270, 90, 150], [330, 30, 210, 150], [30, 270, 90, 210]],
  }],
]);

enum elabyrinth {
  'labyrinth2' = 2,
  'labyrinth3',
  'labyrinth4',
  'labyrinth5',
};

enum egold {
  'gold1' = 1,
  'gold2' ,
  'gold3',
  'gold4',
  'gold5',
};

export default function IconContainer({ code, size, shape, rotate }: { code: string, size: number, shape: number, rotate: number }) {

  return (
    <div
      className={styles.iconcontainer}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {/*@ts-ignore*/}
      {code in dirs.get(shape) && (
          //@ts-ignore
          dirs.get(shape)[code][0].map((dir, i, arr) => (
            <div
              key={`arrowItem_${i + 1}`}
              className={clsx(styles.arrow, styles[`arrow${arr.length}`])}
              style={{ transform: `rotate(${dir}deg)` }}
            >
              <HandySvg
                className={styles.icon}
                //@ts-ignore
                src={eicon.arrow}
                width={size}
                height={size}
              />
            </div>
          ))
      )}
      {code in elabyrinth && (
        //@ts-ignore
        repeat(elabyrinth[code], idx => (
          <UnitFieldItem
            key={`labyrinthItem_${idx + 1}`}
            count={idx}
            //@ts-ignore
            angle={360 / Number(elabyrinth[code])}
            size={size * 0.25}
            translate={size * 0.35}
          >
            <LabyrinthItem
              count={idx + 1}
              size={size * 0.4}
            />
          </UnitFieldItem>
        ))
      )}
      {code in eicon && (
        <div
          className={styles.iconcontainer}
          style={{ transform: `rotate(-${rotate + (code === "gun" ? 280 : 0)}deg)` }}
        >
          <HandySvg
            className={styles.icon}
            //@ts-ignore
            src={eicon[code]}
            width={size}
            height={size}
          />
          {code in egold && (
            <UnitFieldItem
              key={code}
              inCenter={true}
              //@ts-ignore
              count={egold[code]}
              angle={0}
              size={size * 0.3}
              translate={size * 0.25}
            >
              <MoneyItem
                //@ts-ignore
                money={egold[code]}
                size={size * 0.4}
                coords={[0, 0]}
              />
            </UnitFieldItem>
          )}
        </div>
      )}
    </div>
  );
}