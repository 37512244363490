import React, { useContext } from 'react';
import clsx from 'clsx';
import { gameContext } from '../../../providers/GameProvider';
import { sameCoords } from '../../../lib/sameCoords';
import { MoneyItem } from '../UnitField/MoneyItem';
import { IShipProps } from './interfaces';
import styles from './style.module.sass';
import { HandySvg } from 'handy-svg';
import icons from "./pirateship.svg";
import { tooltipContext } from "../../../providers/TooltipProvider";

const colors = [
  '',
  'красного',
  'оранжевого',
  'желтого',
  'зеленого',
  'голубого',
  'синего',
  'фиолетового',
  'розового',
];

export function Ship({ coords: [x, y], size, avatar = false }: IShipProps) {
  const { units, rotate } = useContext(gameContext);
  const ship = units.find(({ coords, type }) => sameCoords(coords, [x, y]) && type === 'ship');
  const { showTooltip, hideTooltip } = useContext(tooltipContext);

  return (
    <>
      {ship ? (
        <div
          id={avatar ? `avatar${ship?.id}` : ship?.id}
          className={clsx(
            styles.playership,
            { [styles.active]: ship.isActive },
            { [styles.inactive]: !ship.isInGame },
          )}
          style={{
            transform: `rotate(-${avatar ? 0 : rotate}deg)`,
            opacity: Number(avatar),
          }}
          onMouseOver={({ clientX, clientY }) => ship && showTooltip(
            'unit',
            [clientX, clientY],
            `Корабль ${colors[+ship.color]} игрока`,
            <div>имя игрока: {ship.username}<br />открыто золотых: {ship.found}</div>,
            ship.gold,
          )}
          onMouseOut={() => false && hideTooltip()}
        >
          <HandySvg
            src={icons}
            className={clsx(
              styles[`ship${ship ? ship.color : 0}` as keyof typeof styles],
              styles.rolling,
            )}
            width={size * 0.8}
            height={size * 0.8}
          />
          {ship.gold ? (
            <div style={{ position: 'absolute' }}>
              <MoneyItem
                money={ship.gold}
                size={size * 0.3}
                coords={[x, y]}
                isRotate={false}
              />
            </div>
          ) : ''}
        </div>
      ) : ''}
    </>
  );
}
