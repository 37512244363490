import React from 'react';
import { Icon } from '../../Icon';
import { Button } from '../Button';
import { EICON } from '../../Icon/eicon.enum';

export function PlayButton({ createBtnsClick, size }: { createBtnsClick: Function, size: number }) {
  return (
    <Button onClick={() => createBtnsClick()}>
      <Icon name={EICON.arrowRight} size={size} />
    </Button>
  );
}
