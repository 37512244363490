import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import Providers from "./providers/Providers";
import Pages from "./pages/Pages";
import Modal from "./pages/modal/Modal";
import Tooltipe from "./pages/tooltipe/Tooltipe";
import './App.sass';

function App() {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return (
    <BrowserRouter>
      {mounted &&
        <Providers>
          <Pages />
          <Modal />
          <Tooltipe />
        </Providers>
      }
    </BrowserRouter>
  );
}

export default App;
