'use client';

import React, { createContext, useState } from 'react';
import { ESHAPES, PAGES } from "./pagesConfig";
import { IProvidersProps } from "../interfaces/providers.interface";
import { IPagesContextProps } from "../interfaces/pages-provider.interface";
import { emptyFn } from "../../lib/emptyFn";

export const pagesContext = createContext<IPagesContextProps>({
  activePanel: PAGES.start.path,
  setActivePanel: emptyFn,
  activeShape: ESHAPES.hexagon,
  setActiveShape: emptyFn,
});

export function PagesProvider({ children }: IProvidersProps) {
  const { Provider } = pagesContext;
  const [activePanel, setActivePanel] = useState<string>(PAGES.start.path);
  const [activeShape, setActiveShape] = useState<keyof typeof ESHAPES>(ESHAPES.hexagon);

  return (
    <Provider value={{ activePanel, setActivePanel, activeShape, setActiveShape }}>
      {children}
    </Provider>
  );
}
