import React from 'react';
import img from './loading.gif';
import styles from './style.module.sass';

export function Loader() {
  return (
    <div className={styles.loader} style={{
      width: 300,
      height: 300,
      borderRadius: '50%',
      backgroundImage: `url(${img})`,
      backgroundSize: 'contain'
    }} />
  );
}
