import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { usePageMount } from '../../hooks/usePageMount';
import { requestContext } from '../../providers/RequestProvider';
import { userContext } from '../../providers/UserProvider';
import { IUser } from '../../interfaces/user.interface';
import { IVkAuthFormFields } from './interfaces';

export default function Vk() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { request } = useContext(requestContext);
  const { setUserId, setToken } = useContext(userContext);

  usePageMount(() => {
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      if (params.has('user_id') && params.has('access_token')) {
        request<IVkAuthFormFields, any>({
          data: {
            userId: Number(params.get('user_id')),
            accessToken: params.get('access_token') || '',
            expiresIn: params.has('expires_in') ? Number(params.get('expires_in')) : 86400,
            email: params.get('email') || '',
          },
          method: 'POST',
          path: 'auth/vk',
          success: (data: IUser) => {
            if (data && data.username && data.userId && data.accessToken && data.expiresIn) {
              setUserId(data.userId);
              setToken(data.accessToken, +data.expiresIn);
              navigate('/');
            }
          },
        });
      }
    }
  }, 'vk');

  return (<></>);
}
