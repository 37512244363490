import React, {useContext, useEffect, useState} from 'react';
import { getFieldOffset } from '../../lib/getFieldOffset';
import { FieldButton } from '../Buttons/FieldButton';
import { calculateHexFieldsBySide } from '../../lib/calculateHexFieldsBySide';
import { sameCoords } from '../../lib/sameCoords';
import { IMenuMapProps } from './interfaces';
import { IMap } from '../../interfaces/map.interface';
import {gameContext} from "../../providers/GameProvider";

export function MenuMap({
  maps, mapSize, fieldClick, idName, buttons, shape, createBtnsClick, createBtns, fulling = 75
}: IMenuMapProps) {
  const [mapMenu, setMapMenu] = useState<IMap[] | []>([]);
  const [fieldSize, setFieldSize] = useState<number>(0);
  const { rotate } = useContext(gameContext);

  const generateMap = () => {
    const menu: { [rkey: number]: { [key: number]: IMap | string; } } = {};
    let mapLen = 1;
    if (maps && maps.length) {
      maps.forEach(map => {
        if (map.position && map.position.length) {
          mapLen = Math.max(mapLen, Math.abs(map.position[0]), Math.abs(map.position[1]))
        }
      });

      if (((maps.length / calculateHexFieldsBySide(mapLen)) * 100) > fulling) {
        mapLen += 1;
      }
    }
    const len = mapLen * 2;
    const side = mapLen + 1;
    for (let i = 0; i <= len; i++) {
      const col: { [key: number]: IMap | string; } = {};
      const hexLen = shape !== 4
        ? (side + i) >= (side * 2) ? ((side * 2) - i + (side - 2)) : (side + i)
        : len + 1;
      for (let j = 0; j < hexLen; j++) {
        const x = mapLen - len + i;
        const y = mapLen - hexLen + j + 1;
        let map: any = 0;
        if (maps && maps.length) {
          map = maps.find(map => map.position && sameCoords(map.position, [x, y]));
        }
        const empty = typeof createBtns === 'function' ? {
          mapId: 'empty',
          position: [x, y],
        } : 0;
        //@ts-ignore
         col[j] = map || empty;
      }
      menu[i] = col;
    }

    return menu;
  };

  useEffect(() =>  {
    let menu = generateMap();
    //@ts-ignore
    setMapMenu(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps]);

  useEffect(() => {
    if (Object.keys(mapMenu).length) {
      setFieldSize(mapSize / Object.keys(mapMenu).length);
    }
  }, [mapMenu, mapSize]);

  return (
    <div
      data-id={'maps'}
      style={{
        position: 'relative',
        width: mapSize,
        height: mapSize,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {mapMenu && fieldSize
        ? (
          Object.values(mapMenu).map((row, rIdx) => (
            row ? (
              Object.values(row).map((col, cIdx) => (
                col ? (
                  <FieldButton
                    key={`key_menu_${rIdx}_${cIdx}`}
                    size={fieldSize}
                    shape={shape || 6}
                    id={`id_menu_${rIdx}_${cIdx}`}
                    name={''}
                    gold={0}
                    description={''}
                    placeStyle={getFieldOffset(
                      [rIdx, cIdx],
                      mapSize,
                      [Object.values(row).length, Object.values(mapMenu).length],
                      shape || 6,
                    )}
                    style={{
                      width: fieldSize,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#ffffff0f',
                    }}
                    coords={[rIdx, cIdx]}
                    //@ts-ignore
                    onClick={() => col && col[idName] && fieldClick(col[idName])}
                  >
                    {/*{col.position[0]} : {col.position[1]}*/}
                    {(col.mapId === 'empty')
                      ? createBtns({ createBtnsClick, position: col.position, size: fieldSize })
                      : buttons({ ...col, position: col.position, size: fieldSize, rotate })}
                  </FieldButton>
                ) : ''
              ))
            ) : ''
          ))
        )
        : createBtns && createBtns({
          createBtnsClick,
          position: [0, 0],
          size: fieldSize,
        })
      }
    </div>
  );
}
