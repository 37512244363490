import React from 'react';
import { HandySvg } from "handy-svg";
import { getFieldOffset } from "../../../lib/getFieldOffset";
import { fieldMapEditorGenerator } from "../../../lib/fieldMapEditorGenerator";
import { getBackgroundCommonStyle } from "../../../lib/getBackgroundCommonStyle";
import { MapFrame } from "../../../ui/Map/MapFrame";
import { FieldButton } from "../../../ui/Buttons/FieldButton";
import Surface from "../../../ui/Surface/Surface";

import { IFieldMapEditorProps } from "./interfaces";

import styles from './style.module.sass';

import icons from "../../../ui/Map/Ship/pirateship.svg";

import IconContainer from "../ChooseField/IconContainer/IconContainer";
import clsx from "clsx";



export default function FieldMapEditor({ map, players, rotate, setCoords, }: IFieldMapEditorProps) {

  const mapWidth = Math.round(Math.min(window.innerWidth, window.innerHeight) / 140) * 100;
  const fieldSize = Math.round(mapWidth/Object.values(map.map).length);

  const createCls = (col: number): string => col === 0 ? 'earth' : `sea${col > 0 ? col.toString() : ''}`;

  return (
    <MapFrame
      size={mapWidth}
      shape={map.shape}
      rotate={rotate}
      durationRotate={true}
    >
        {fieldMapEditorGenerator({
            map: map.map,
            size: mapWidth,
            shape: map.shape,
          }, (value, coords, len, childProps) => (
            <FieldButton
              //@ts-ignore
              className={typeof value === 'number' ? styles[createCls(value)] : styles.earth}
              name={'earth'}
              gold={0}
              description={''}
              onClick={() => setCoords(coords)}
              field={(typeof value === 'string' || value === 0) ? 'earth' : 'sea'}
              children={
                typeof value !== 'number' && (
                  <>
                    <Surface
                      surface={coords[0] + coords[1]}
                      size={childProps.size * 0.9}
                      rotate={rotate}
                    />
                    <IconContainer
                      rotate={rotate}
                      code={value}
                      shape={map.shape}
                      size={childProps.size * 0.8}
                    />
                  </>
                )
              }
              {...childProps}
              style={getBackgroundCommonStyle(coords, mapWidth, len, map.shape, 0)}
            />
        ))}
        {players && Object.entries(players).map(([index, { coords: [x, y], isActive }]) => (
          <div
            key={index}
            style={getFieldOffset(
              [x, y],
              mapWidth,
              [Object.values(Object.values(map.map)[x]).length, Object.values(map.map).length],
              map.shape,
              rotate,
            )}
            className={clsx(
              styles.ship,
              { [styles.active]: isActive },
            )}
            onClick={() => setCoords([x, y])}
          >
            <HandySvg
              src={icons}
              className={styles[`ship${index}`]}
              width={fieldSize}
              height={fieldSize}
            />
          </div>
        ))}
    </MapFrame>
  );
}
