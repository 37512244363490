import React, { useContext } from 'react';
import styles from './style.module.sass';
import { ILabyrinthItemProps } from "./interfaces";
import { gameContext } from "../../../../providers/GameProvider";
import clsx from "clsx";

export function LabyrinthItem({ children = '',  count, size }: ILabyrinthItemProps) {
  const { rotate } = useContext(gameContext);

  return (
    <div className={clsx(styles.labyrinthitem, styles[`labyrinth${count}`])}>
      <div
        className={styles.count}
        style={{
          transform: `rotate(-${rotate}deg)`,
          fontSize: size / 2,
        }}
      >
      </div>
      {children}
    </div>
  );
}
