import React, { createContext, ReactElement, useEffect, useState } from 'react';
import { ITooltipContextProps } from "../interfaces/tooltip-provider.interface";
import { IProvidersProps } from "../interfaces/providers.interface";
import { emptyFn } from "../../lib/emptyFn";
import { declinationOfNumber } from "../../lib/declinationOfNumber";

export const tooltipContext = createContext<ITooltipContextProps>({
  type: '',
  content: <></>,
  show: false,
  coords: [0, 0],
  showTooltip: emptyFn,
  hideTooltip: emptyFn,
});

export function TooltipProvider({ children }: IProvidersProps) {
  const { Provider } = tooltipContext;
  const [data, setData] = useState({
    type: '',
    coords: [0, 0],
    header: '',
    description: <></>,
  });
  const [coords, setCoords] = useState([0, 0]);
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      if (
        data.type &&
        data.coords[0] !== 0 &&
        data.coords[0] === coords[0] &&
        data.coords[1] === coords[1]
      ) {
        setShow(true);
      }
    }, 2000);
  }, [data]);

  const showTooltip = (type: string, coords: number[], header: string, description: ReactElement, gold: number) => {
    setShow(false);
    setData({ type, coords, header, description });
    setContent(
      <>
        <h3 style={{ fontWeight: 'bold', marginBottom: '3px', }}>{data.header}</h3>
        {!!gold && (
            <span style={{
              textShadow: `0px 0px 4px gold, 0px 0px 6px gold, 0px 0px 8px gold, 0px 0px 10px gold`,
              fontWeight: 'bold',
              marginBottom: '3px',
            }}>
              {gold} {declinationOfNumber(gold, 'золотая монета', 'золотые монеты', 'золотых монет')}
            </span>
        )}
        {data.description}
      </>
    );
    setCoords(coords);
  }
  const hideTooltip = () => {
    setShow(false);
    setContent(<></>);
    setData({
      type: '',
      coords: [0, 0],
      header: '',
      description: <span></span>,
    });
  }

  return (
    <Provider value={{ type: data.type, show, content, coords, showTooltip, hideTooltip }}>
      {children}
    </Provider>
  );
}
